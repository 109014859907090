import React from 'react'

function SectionSideBySide({ img, heading, text }) {
  return (
    <section className="w-full py-12 sm:py-20 bg-white">
      <div className="container mx-auto px-4 sm:px-6">
        <div className="flex flex-col md:flex-row items-center gap-8 sm:gap-16">
          {/* Image Section */}
          <div className="w-full md:w-1/2">
            <div className="border-2 border-primary p-2">
              <div className="aspect-w-4 aspect-h-3">
                <img 
                  src={img} 
                  alt={heading}
                  className="w-full h-full object-cover"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          
          {/* Content Section */}
          <div className="w-full md:w-1/2 text-center">
            <h2 className="text-3xl sm:text-4xl font-secondary mb-4 sm:mb-8">
              {heading}
            </h2>
            <p className="font-primary text-base sm:text-lg leading-relaxed max-w-xl mx-auto">
              {text}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionSideBySide
