import React from 'react';

const HolidayDatesSection = ({ 
  holidayDates, 
  newHolidayDate, 
  onNewHolidayDateChange, 
  onAddHoliday, 
  onRemoveHoliday 
}) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-4">Holiday Dates</h3>
      <div className="flex space-x-4 mb-4">
        <input
          type="date"
          value={newHolidayDate}
          onChange={(e) => onNewHolidayDateChange(e.target.value)}
          className="form-input"
        />
        <button
          onClick={onAddHoliday}
          className="button-secondary"
        >
          Add Holiday
        </button>
      </div>
      <div className="space-y-2">
        {holidayDates.map(date => (
          <div key={date} className="flex items-center justify-between bg-gray-50 p-2 rounded">
            <span>{new Date(date).toLocaleDateString()}</span>
            <button
              onClick={() => onRemoveHoliday(date)}
              className="text-red-600 hover:text-red-800"
            >
              Remove
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HolidayDatesSection;
