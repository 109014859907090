import React from 'react';

const BusinessHoursSection = ({ businessHours, onHoursChange, onDayToggle }) => {
  const orderedDays = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
  ];

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-4">Business Hours</h3>
      <div className="space-y-4">
        {orderedDays.map(day => (
          <div key={day} className="flex items-center space-x-4">
            <div className="w-32 capitalize">{day}</div>
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={businessHours[day].isOpen}
                onChange={() => onDayToggle(day)}
                className="form-checkbox h-4 w-4 text-primary"
              />
              <span className="ml-2">Open</span>
            </label>
            <input
              type="time"
              value={businessHours[day].start}
              onChange={(e) => onHoursChange(day, 'start', e.target.value)}
              disabled={!businessHours[day].isOpen}
              className="form-input"
            />
            <span>to</span>
            <input
              type="time"
              value={businessHours[day].end}
              onChange={(e) => onHoursChange(day, 'end', e.target.value)}
              disabled={!businessHours[day].isOpen}
              className="form-input"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BusinessHoursSection;
