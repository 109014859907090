import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyD_x8K9ldpP5Y3CeZABq7MNDuRZ7uc1KDE",
  authDomain: "skin-chalet.firebaseapp.com",
  projectId: "skin-chalet",
  storageBucket: "skin-chalet.appspot.com",
  messagingSenderId: "532754871105",
  appId: "1:532754871105:web:7e1b34adfaad824de227c0",
  measurementId: "G-SWCRJ1N91P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

// Export initialized services
export { 
  app,
  analytics,
  auth,
  db,
  storage,
  functions
};
