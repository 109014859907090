import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import dayjs from 'dayjs';

const BookingConfirmation = () => {
  const location = useLocation();
  const { bookingId, bookingData } = location.state || {};

  if (!bookingData) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-white pt-32">
        <div className="text-center px-4">
          <div className="text-xl text-gray-600">
            No booking information found. Please try making a new booking.
          </div>
          <Link
            to="/booking"
            className="mt-6 inline-block px-8 py-3 bg-primary text-white hover:bg-primary/90 transition-colors duration-200 rounded-lg text-sm font-medium tracking-wide"
          >
            MAKE A BOOKING
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white pt-32">
      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8">
          <div className="mb-6">
            <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto">
              <svg className="w-8 h-8 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            </div>
          </div>
          <h1 className="text-2xl sm:text-3xl font-secondary text-primary tracking-wide mb-4">
            Booking Confirmed
          </h1>
          <p className="text-gray-600 font-primary">
            {bookingData.status === 'confirmed' 
              ? 'Your appointment has been confirmed.'
              : 'Your appointment request has been received and is pending confirmation.'}
          </p>
        </div>

        <div className="bg-white border border-gray-200 rounded-lg p-6 sm:p-8 shadow-sm mb-8">
          <div className="space-y-6">
            {/* Booking Reference */}
            <div>
              <h2 className="text-sm font-body tracking-widest text-gray-500 mb-2">
                BOOKING REFERENCE
              </h2>
              <p className="font-secondary text-lg">{bookingId}</p>
            </div>

            {/* Services */}
            <div>
              <h2 className="text-sm font-body tracking-widest text-gray-500 mb-2">
                SERVICES
              </h2>
              <div className="space-y-2">
                {bookingData.services.map((service, index) => (
                  <div key={index} className="flex justify-between items-baseline">
                    <p className="font-primary">
                      {service.title}
                      {service.selectedVariation && (
                        <span className="text-gray-600">
                          {' '}({service.selectedVariation.name})
                        </span>
                      )}
                    </p>
                    <p className="font-secondary text-primary">£{service.price}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* Date & Time */}
            <div>
              <h2 className="text-sm font-body tracking-widest text-gray-500 mb-2">
                DATE & TIME
              </h2>
              <p className="font-primary">
                {dayjs(bookingData.dateTime).format('MMMM D, YYYY [at] h:mm A')}
              </p>
            </div>

            {/* Location */}
            {bookingData.locationName && (
              <div>
                <h2 className="text-sm font-body tracking-widest text-gray-500 mb-2">
                  LOCATION
                </h2>
                <p className="font-primary">{bookingData.locationName}</p>
              </div>
            )}

            {/* Total */}
            <div className="pt-4 border-t border-gray-200">
              <div className="flex justify-between items-baseline">
                <h2 className="text-sm font-body tracking-widest text-gray-500">
                  TOTAL
                </h2>
                <p className="text-xl font-secondary text-primary">
                  £{bookingData.totalPrice}
                </p>
              </div>
              {bookingData.depositAmount && (
                <div className="flex justify-between items-baseline mt-2">
                  <h2 className="text-sm font-body tracking-widest text-gray-500">
                    DEPOSIT PAID
                  </h2>
                  <p className="font-secondary text-primary">
                    £{bookingData.depositAmount}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="text-center space-y-4 mb-16">
          <p className="text-gray-600 font-primary">
            A confirmation email has been sent to your email address.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <Link
              to="/my-account"
              className="px-8 py-3 bg-transparent border-2 border-primary text-primary hover:bg-primary hover:text-white transition-colors duration-200 rounded-lg text-sm font-medium tracking-wide"
            >
              VIEW MY BOOKINGS
            </Link>
            <Link
              to="/"
              className="px-8 py-3 bg-primary text-white hover:bg-primary/90 transition-colors duration-200 rounded-lg text-sm font-medium tracking-wide"
            >
              RETURN HOME
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmation;
