// Stripe configuration
const STRIPE_PUBLIC_KEY = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_STRIPE_DEV_PUBLIC_KEY
  : process.env.REACT_APP_STRIPE_PUBLIC_KEY;

// Ensure we have a key
if (!STRIPE_PUBLIC_KEY) {
  console.error('Stripe public key is missing. Check your environment variables.');
}

// Configure Stripe Elements
export const stripeElementsOptions = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap',
    },
  ],
  locale: 'en',
  appearance: {
    theme: 'stripe',
    variables: {
      colorPrimary: '#000000',
      colorBackground: '#ffffff',
      colorText: '#32325d',
      colorDanger: '#fa755a',
      fontFamily: 'Inter, sans-serif',
      borderRadius: '4px',
    },
  },
  // Always show the error messages in development
  loader: process.env.NODE_ENV === 'development' ? 'always' : 'auto',
};

// Export a function that ensures we have a key before returning it
export const getStripeKey = () => {
  if (!STRIPE_PUBLIC_KEY) {
    throw new Error('Stripe public key is missing. Check your environment variables.');
  }
  return STRIPE_PUBLIC_KEY;
};
