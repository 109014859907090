import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { db } from '../../config/firebase';
import { collection, getDocs, query, orderBy, where, doc, getDoc, onSnapshot } from 'firebase/firestore';
import ListView from './BookingViews/ListView';
import CalendarView from './BookingViews/CalendarView';
import WeeklyView from './BookingViews/WeeklyView';
import DailyView from './BookingViews/DailyView';
import BookingDetails from './BookingViews/BookingDetails';
import ManualBooking from './BookingViews/ManualBooking';

const ViewBookings = () => {
  // Initialize viewMode based on screen size
  const [viewMode, setViewMode] = useState(() => {
    return window.innerWidth < 640 ? 'daily' : 'weekly';
  });
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [bookings, setBookings] = useState([]);
  const [blockedTimes, setBlockedTimes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(dayjs());
  const [currentWeek, setCurrentWeek] = useState(dayjs().startOf('week'));
  const [currentDay, setCurrentDay] = useState(dayjs());
  const [showManualBooking, setShowManualBooking] = useState(false);
  const [showFutureOnly, setShowFutureOnly] = useState(true);
  
  // Update viewMode when window is resized
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        if (viewMode === 'weekly') {
          setViewMode('daily');
        }
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [viewMode]);

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, 'settings', 'booking'), (doc) => {
      if (doc.exists()) {
        const settingsData = doc.data();
        setBlockedTimes(settingsData.blockedTimes || []);
      }
    }, (error) => {
      console.error('Error listening to blocked times:', error);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const bookingsQuery = query(
          collection(db, 'bookings'),
          orderBy('dateTime', 'asc')
        );
        const snapshot = await getDocs(bookingsQuery);
        
        const bookingsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          dateTime: doc.data().dateTime?.toDate(),
          isImported: !doc.data().userId,
          adminNotes: doc.data().adminNotes || ''
        }));

        setBookings(bookingsData);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDateClick = (date) => {
    const selectedDayjs = dayjs(date);
    setSelectedDate(date);
    setCurrentWeek(selectedDayjs.startOf('week'));
    setCurrentDay(selectedDayjs);
    setViewMode('daily');
  };

  const handleBookingClick = (booking) => {
    setSelectedBooking(booking);
  };

  const handleCloseBookingDetails = () => {
    setSelectedBooking(null);
  };

  const handleBookingUpdate = (updatedBooking) => {
    setBookings(prevBookings => 
      prevBookings.map(booking => 
        booking.id === updatedBooking.id ? updatedBooking : booking
      )
    );
    if (selectedBooking?.id === updatedBooking.id) {
      setSelectedBooking(updatedBooking);
    }
  };

  const handlePreviousMonth = () => {
    setCurrentMonth(prev => prev.subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setCurrentMonth(prev => prev.add(1, 'month'));
  };

  const handlePreviousWeek = () => {
    setCurrentWeek(prev => prev.subtract(1, 'week'));
  };

  const handleNextWeek = () => {
    setCurrentWeek(prev => prev.add(1, 'week'));
  };

  const handlePreviousDay = () => {
    setCurrentDay(prev => prev.subtract(1, 'day'));
  };

  const handleNextDay = () => {
    setCurrentDay(prev => prev.add(1, 'day'));
  };

  const handleTodayClick = () => {
    const today = dayjs();
    setCurrentWeek(today.startOf('week'));
    setCurrentMonth(today);
    setCurrentDay(today);
    setSelectedDate(today.toDate());
  };

  const getFilteredBookings = () => {
    if (!showFutureOnly) return bookings;

    const today = dayjs().startOf('day');
    return bookings.filter(booking => {
      const bookingDate = dayjs(booking.dateTime);
      return bookingDate.isSame(today, 'day') || bookingDate.isAfter(today);
    });
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-xl text-gray-600">Loading bookings...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-xl text-red-600">{error}</div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
          <div className="w-full sm:w-auto">
            <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-2 sm:mb-0">View Bookings</h2>
          </div>
          <div className="w-full sm:w-auto">
            <button
              onClick={() => setShowManualBooking(true)}
              className="w-full sm:w-auto px-4 py-2 rounded-md bg-primary text-white hover:bg-primary/90"
            >
              Manual Booking
            </button>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
          <div className="flex flex-wrap gap-2 w-full sm:w-auto">
            <button
              onClick={() => setViewMode('list')}
              className={`flex-1 sm:flex-none px-3 py-2 rounded-md text-sm ${
                viewMode === 'list'
                  ? 'bg-primary text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
            >
              List
            </button>
            <button
              onClick={() => setViewMode('calendar')}
              className={`flex-1 sm:flex-none px-3 py-2 rounded-md text-sm ${
                viewMode === 'calendar'
                  ? 'bg-primary text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
            >
              Calendar
            </button>
            {/* Weekly view button - hidden on mobile */}
            <button
              onClick={() => setViewMode('weekly')}
              className={`hidden sm:block px-3 py-2 rounded-md text-sm ${
                viewMode === 'weekly'
                  ? 'bg-primary text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
            >
              Weekly
            </button>
            <button
              onClick={() => setViewMode('daily')}
              className={`flex-1 sm:flex-none px-3 py-2 rounded-md text-sm ${
                viewMode === 'daily'
                  ? 'bg-primary text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
            >
              Daily
            </button>
          </div>

          {(viewMode === 'weekly' || viewMode === 'calendar' || viewMode === 'daily') && (
            <button
              onClick={handleTodayClick}
              className="w-full sm:w-auto px-4 py-2 rounded-md bg-primary text-white hover:bg-primary/90"
            >
              Today
            </button>
          )}
        </div>
      </div>

      {viewMode === 'list' && (
        <div>
          <div className="mb-4">
            <label className="flex items-center cursor-pointer">
              <div className="relative">
                <input
                  type="checkbox"
                  className="sr-only"
                  checked={showFutureOnly}
                  onChange={() => setShowFutureOnly(!showFutureOnly)}
                />
                <div className={`block w-14 h-8 rounded-full transition-colors duration-200 ease-in-out ${showFutureOnly ? 'bg-primary' : 'bg-gray-400'}`}></div>
                <div className={`absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition-transform duration-200 ease-in-out ${showFutureOnly ? 'transform translate-x-6' : ''}`}></div>
              </div>
              <span className="ml-3 text-sm sm:text-base text-gray-700">
                {showFutureOnly ? "Showing Today & Future Bookings" : "Showing All Bookings"}
              </span>
            </label>
          </div>
          <ListView 
            bookings={getFilteredBookings()} 
            onBookingClick={handleBookingClick} 
          />
        </div>
      )}
      
      {viewMode === 'calendar' && (
        <CalendarView 
          currentMonth={currentMonth}
          onPreviousMonth={handlePreviousMonth}
          onNextMonth={handleNextMonth}
          onDateClick={handleDateClick}
          bookings={bookings}
          blockedTimes={blockedTimes}
        />
      )}
      
      {viewMode === 'weekly' && (
        <WeeklyView 
          currentWeek={currentWeek}
          onPreviousWeek={handlePreviousWeek}
          onNextWeek={handleNextWeek}
          onDateClick={handleDateClick}
          onBookingClick={handleBookingClick}
          bookings={bookings}
          blockedTimes={blockedTimes}
        />
      )}

      {viewMode === 'daily' && (
        <DailyView 
          currentDate={currentDay}
          onPreviousDay={handlePreviousDay}
          onNextDay={handleNextDay}
          onBookingClick={handleBookingClick}
          bookings={bookings}
          blockedTimes={blockedTimes}
        />
      )}

      {selectedBooking && (
        <BookingDetails 
          booking={selectedBooking}
          onClose={handleCloseBookingDetails}
          onBookingUpdate={handleBookingUpdate}
        />
      )}

      {showManualBooking && (
        <ManualBooking 
          onClose={() => {
            setShowManualBooking(false);
            window.location.reload();
          }} 
        />
      )}
    </div>
  );
};

export default ViewBookings;
