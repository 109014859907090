import { init, send } from '@emailjs/browser';
import { EMAILJS_CONFIG } from '../config/emailjs';

// Initialize EmailJS with public key
console.log('Initializing EmailJS with config:', {
  serviceId: EMAILJS_CONFIG.SERVICE_ID,
  templateIds: Object.keys(EMAILJS_CONFIG.TEMPLATE_IDS),
  publicKey: EMAILJS_CONFIG.PUBLIC_KEY.substring(0, 4) + '...' // Log only first 4 chars for security
});

init(EMAILJS_CONFIG.PUBLIC_KEY);

/**
 * Base email sending function
 * @param {string} templateId - The EmailJS template ID
 * @param {Object} templateParams - Template parameters
 * @returns {Promise} - Returns a promise that resolves when email is sent
 */
const sendEmail = async (templateId, templateParams) => {
  console.log('Attempting to send email with:', {
    templateId,
    params: templateParams,
    serviceId: EMAILJS_CONFIG.SERVICE_ID
  });

  try {
    // Using send directly from @emailjs/browser
    const response = await send(
      EMAILJS_CONFIG.SERVICE_ID,
      templateId,
      {
        ...templateParams,
        reply_to: templateParams.to_email // Ensure reply_to is set
      },
      EMAILJS_CONFIG.PUBLIC_KEY // Pass public key explicitly
    );
    
    console.log('Email sent successfully:', response);
    return response;
  } catch (error) {
    console.error('Error sending email:', error);
    console.error('Error details:', {
      code: error.code,
      text: error.text,
      name: error.name,
      message: error.message,
      status: error.status
    });
    
    // Enhanced error handling
    let errorMessage = 'Failed to send email';
    if (error.text) {
      errorMessage = error.text;
    } else if (error.message) {
      errorMessage = error.message;
    }
    
    // Throw a more informative error
    throw new Error(`EmailJS Error: ${errorMessage} (Status: ${error.status || 'unknown'})`);
  }
};

/**
 * Send contact form email
 * @param {Object} contactDetails - Contact form information
 */
export const sendContactFormEmail = async (contactDetails) => {
  console.log('Preparing contact form email with details:', contactDetails);
  
  const templateParams = {
    from_name: contactDetails.name,
    from_email: contactDetails.email,
    message: contactDetails.message,
    to_email: 'info@skinchalet.com', // The business email that receives contact form submissions
    reply_to: contactDetails.email
  };

  console.log('Sending contact form email with params:', templateParams);
  return sendEmail(EMAILJS_CONFIG.TEMPLATE_IDS.CONTACT_FORM, templateParams);
};

/**
 * Send booking confirmation email
 * @param {Object} bookingDetails - Booking information
 */
export const sendBookingConfirmation = async (bookingDetails) => {
  console.log('Preparing booking confirmation email with details:', bookingDetails);
  
  const templateParams = {
    to_name: bookingDetails.customerName,
    booking_date: bookingDetails.date,
    booking_time: bookingDetails.time,
    service_name: bookingDetails.serviceName,
    total_amount: bookingDetails.totalAmount,
    booking_id: bookingDetails.bookingId,
    to_email: bookingDetails.customerEmail,
    reply_to: bookingDetails.customerEmail
  };

  // Add deposit information if available
  if (bookingDetails.depositAmount) {
    templateParams.deposit_amount = bookingDetails.depositAmount.toFixed(2);
    templateParams.remaining_balance = (bookingDetails.totalAmount - bookingDetails.depositAmount).toFixed(2);
  }

  console.log('Sending booking confirmation with params:', templateParams);
  return sendEmail(EMAILJS_CONFIG.TEMPLATE_IDS.BOOKING_CONFIRMATION, templateParams);
};

/**
 * Send appointment reminder email
 * @param {Object} appointmentDetails - Appointment information
 */
export const sendAppointmentReminder = async (appointmentDetails) => {
  console.log('Preparing appointment reminder email with details:', appointmentDetails);
  
  const templateParams = {
    to_name: appointmentDetails.customerName,
    appointment_date: appointmentDetails.date,
    appointment_time: appointmentDetails.time,
    service_name: appointmentDetails.serviceName,
    to_email: appointmentDetails.customerEmail,
    reply_to: appointmentDetails.customerEmail,
    special_instructions: appointmentDetails.specialInstructions || ''
  };

  return sendEmail(EMAILJS_CONFIG.TEMPLATE_IDS.APPOINTMENT_REMINDER, templateParams);
};

/**
 * Send welcome email to new users
 * @param {Object} userDetails - User information
 */
export const sendWelcomeEmail = async (userDetails) => {
  console.log('Preparing welcome email with details:', userDetails);
  
  const templateParams = {
    to_name: userDetails.name,
    to_email: userDetails.email,
    reply_to: userDetails.email,
    user_id: userDetails.userId
  };

  return sendEmail(EMAILJS_CONFIG.TEMPLATE_IDS.WELCOME_EMAIL, templateParams);
};

/**
 * Send password reset email
 * @param {Object} userDetails - User information
 */
export const sendPasswordResetEmail = async (userDetails) => {
  console.log('Preparing password reset email with details:', userDetails);
  
  const templateParams = {
    to_name: userDetails.name,
    to_email: userDetails.email,
    reply_to: userDetails.email,
    reset_link: userDetails.resetLink
  };

  return sendEmail(EMAILJS_CONFIG.TEMPLATE_IDS.PASSWORD_RESET, templateParams);
};

/**
 * Send booking cancellation email
 * @param {Object} cancellationDetails - Cancellation information
 */
export const sendCancellationEmail = async (cancellationDetails) => {
  console.log('Preparing cancellation email with details:', cancellationDetails);
  
  const templateParams = {
    to_name: cancellationDetails.customerName,
    booking_id: cancellationDetails.bookingId,
    booking_date: cancellationDetails.date,
    service_name: cancellationDetails.serviceName,
    to_email: cancellationDetails.customerEmail,
    reply_to: cancellationDetails.customerEmail,
    refund_amount: cancellationDetails.refundAmount,
    cancellation_reason: cancellationDetails.reason || 'Not specified'
  };

  return sendEmail(EMAILJS_CONFIG.TEMPLATE_IDS.CANCELLATION, templateParams);
};

/**
 * Send email with retry logic
 * @param {Function} emailFunction - Email sending function to retry
 * @param {Object} details - Email details
 * @param {number} maxRetries - Maximum number of retry attempts
 */
export const sendEmailWithRetry = async (emailFunction, details, maxRetries = 3) => {
  console.log('Starting email send with retry logic:', {
    maxRetries,
    details
  });

  let attempts = 0;
  let lastError;

  while (attempts < maxRetries) {
    try {
      console.log(`Attempt ${attempts + 1} of ${maxRetries}`);
      const result = await emailFunction(details);
      console.log('Email sent successfully on attempt', attempts + 1);
      return result;
    } catch (error) {
      lastError = error;
      attempts++;
      console.error(`Attempt ${attempts} failed:`, error);
      
      if (attempts === maxRetries) {
        console.error('All retry attempts failed');
        break;
      }
      
      const delay = Math.pow(2, attempts) * 1000;
      console.log(`Waiting ${delay}ms before next attempt`);
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }

  throw new Error(`Failed to send email after ${maxRetries} attempts. Last error: ${lastError.message}`);
};
