import React, { useState, useEffect } from 'react';
import { db, storage } from '../../config/firebase';
import { collection, addDoc, getDocs, deleteDoc, doc, query, where, writeBatch, updateDoc, orderBy } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from './StrictModeDroppable';
import { compressImage } from '../../utils/imageCompression';

const AddCategory = () => {
  const [categoryForm, setCategoryForm] = useState({
    name: '',
    shortDescription: '',
    description: '',
    imageUrl: '',
    order: 0
  });
  const [imageFile, setImageFile] = useState(null);
  const [categories, setCategories] = useState([]);
  const [message, setMessage] = useState({ text: '', type: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      // Use orderBy to ensure consistent ordering
      const categoriesQuery = query(collection(db, 'categories'), orderBy('order', 'asc'));
      const querySnapshot = await getDocs(categoriesQuery);
      const categoriesList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCategories(categoriesList);
    } catch (error) {
      setMessage({ text: 'Error fetching categories: ' + error.message, type: 'error' });
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
    }
  };

  const handleDeleteCategory = async (categoryId, categoryName, imageUrl) => {
    if (window.confirm('Are you sure you want to delete this category? All services in this category will also be deleted.')) {
      setIsDeleting(true);
      try {
        // Delete services by categoryId
        const servicesByCategoryIdQuery = query(collection(db, 'services'), where('categoryId', '==', categoryId));
        const servicesByCategoryIdSnapshot = await getDocs(servicesByCategoryIdQuery);
        
        // Delete services by category name (for backward compatibility)
        const servicesByCategoryNameQuery = query(collection(db, 'services'), where('category', '==', categoryName));
        const servicesByCategoryNameSnapshot = await getDocs(servicesByCategoryNameQuery);
        
        // Combine all services to delete
        const allServicesToDelete = [
          ...servicesByCategoryIdSnapshot.docs,
          ...servicesByCategoryNameSnapshot.docs
        ];
        
        // Create a Set of service IDs to remove duplicates
        const uniqueServiceIds = new Set(allServicesToDelete.map(doc => doc.id));
        
        // Delete all services
        const deletePromises = Array.from(uniqueServiceIds).map(serviceId => 
          deleteDoc(doc(db, 'services', serviceId))
        );
        await Promise.all(deletePromises);

        // Delete the category image from storage if it exists
        if (imageUrl) {
          try {
            const imageRef = ref(storage, imageUrl);
            await deleteObject(imageRef);
          } catch (error) {
            console.error('Error deleting image:', error);
            // Continue with category deletion even if image deletion fails
          }
        }

        // Delete the category document
        await deleteDoc(doc(db, 'categories', categoryId));

        setMessage({ text: 'Category and associated services deleted successfully!', type: 'success' });
        fetchCategories(); // Refresh the list
      } catch (error) {
        setMessage({ text: 'Error deleting category: ' + error.message, type: 'error' });
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const handleEditCategory = (category) => {
    setEditingCategory(category.id);
    setCategoryForm({
      name: category.name,
      shortDescription: category.shortDescription || '',
      description: category.description || '',
      imageUrl: category.imageUrl || '',
      order: category.order || 0
    });
    setImageFile(null);
  };

  const resetForm = () => {
    setCategoryForm({
      name: '',
      shortDescription: '',
      description: '',
      imageUrl: '',
      order: categories.length
    });
    setImageFile(null);
    setEditingCategory(null);
    // Reset the file input
    const fileInput = document.getElementById('image');
    if (fileInput) fileInput.value = '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let imageUrl = categoryForm.imageUrl;
      
      if (imageFile) {
        // If editing and there's an existing image, delete it
        if (editingCategory && categoryForm.imageUrl) {
          try {
            const oldImageRef = ref(storage, categoryForm.imageUrl);
            await deleteObject(oldImageRef);
          } catch (error) {
            console.error('Error deleting old image:', error);
          }
        }

        // Compress and upload the new image
        const compressedImage = await compressImage(imageFile);
        const storageRef = ref(storage, `category-images/${Date.now()}-${compressedImage.name}`);
        const snapshot = await uploadBytes(storageRef, compressedImage);
        imageUrl = await getDownloadURL(snapshot.ref);
      }

      const categoryData = {
        name: categoryForm.name,
        shortDescription: categoryForm.shortDescription,
        description: categoryForm.description,
        imageUrl: imageUrl,
        updatedAt: new Date(),
        order: editingCategory ? categoryForm.order : categories.length // Use existing order when editing, append to end when new
      };

      if (editingCategory) {
        await updateDoc(doc(db, 'categories', editingCategory), categoryData);
        setMessage({ text: 'Category updated successfully!', type: 'success' });
      } else {
        categoryData.createdAt = new Date();
        await addDoc(collection(db, 'categories'), categoryData);
        setMessage({ text: 'Category added successfully!', type: 'success' });
      }

      resetForm();
      fetchCategories();
    } catch (error) {
      setMessage({ text: `Error ${editingCategory ? 'updating' : 'adding'} category: ${error.message}`, type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(categories);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Update the order field for all categories
    const batch = writeBatch(db);
    items.forEach((category, index) => {
      const categoryRef = doc(db, 'categories', category.id);
      batch.update(categoryRef, { order: index });
    });

    try {
      await batch.commit();
      setCategories(items);
      setMessage({ text: 'Categories reordered successfully!', type: 'success' });
    } catch (error) {
      setMessage({ text: 'Error updating category order: ' + error.message, type: 'error' });
      fetchCategories(); // Refresh the list to original state
    }
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
      <div className="px-4 py-5 sm:px-6">
        <h2 className="text-lg leading-6 font-medium text-gray-900">
          Manage Categories
        </h2>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Add, edit, and view service categories. Drag and drop to reorder.
        </p>
      </div>

      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        {message.text && (
          <div className={`mb-4 p-4 rounded ${message.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
            {message.text}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="categoryName" className="block text-sm font-medium text-gray-700">
              Category Name
            </label>
            <input
              type="text"
              id="categoryName"
              required
              value={categoryForm.name}
              onChange={(e) => setCategoryForm(prev => ({ ...prev, name: e.target.value }))}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </div>

          <div>
            <label htmlFor="shortDescription" className="block text-sm font-medium text-gray-700">
              Short Description
            </label>
            <input
              type="text"
              id="shortDescription"
              required
              value={categoryForm.shortDescription}
              onChange={(e) => setCategoryForm(prev => ({ ...prev, shortDescription: e.target.value }))}
              placeholder="Brief preview description"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </div>

          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
              Full Description
            </label>
            <textarea
              id="description"
              rows="3"
              value={categoryForm.description}
              onChange={(e) => setCategoryForm(prev => ({ ...prev, description: e.target.value }))}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </div>

          <div>
            <label htmlFor="image" className="block text-sm font-medium text-gray-700">
              Category Image {editingCategory && !imageFile && '(Leave empty to keep current image)'}
            </label>
            <input
              type="file"
              id="image"
              accept="image/*"
              onChange={handleImageChange}
              className="mt-1 block w-full text-sm text-gray-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-md file:border-0
                file:text-sm file:font-semibold
                file:bg-primary file:text-white
                hover:file:bg-primary/90"
            />
            {categoryForm.imageUrl && !imageFile && (
              <div className="mt-2">
                <img 
                  src={categoryForm.imageUrl} 
                  alt="Current category image" 
                  className="w-32 h-32 object-cover rounded-md"
                />
              </div>
            )}
            <p className="mt-1 text-sm text-gray-500">
              Images will be automatically compressed to optimize loading speed
            </p>
          </div>

          <div className="flex space-x-4">
            <button
              type="submit"
              disabled={isLoading}
              className={`button-secondary flex-1 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {isLoading ? (editingCategory ? 'Updating...' : 'Adding...') : (editingCategory ? 'Update Category' : 'Add Category')}
            </button>

            {editingCategory && (
              <button
                type="button"
                onClick={resetForm}
                className="button-secondary bg-gray-500 hover:bg-gray-600 px-4"
              >
                Cancel
              </button>
            )}
          </div>
        </form>

        <div className="mt-6">
          <h3 className="text-lg font-medium text-gray-900">Existing Categories</h3>
          <p className="text-sm text-gray-500 mb-4">Drag and drop categories to reorder them</p>
          <DragDropContext onDragEnd={handleDragEnd}>
            <StrictModeDroppable droppableId="categories">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="space-y-4"
                >
                  {categories.map((category, index) => (
                    <Draggable
                      key={category.id}
                      draggableId={category.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="bg-gray-50 p-4 rounded-md cursor-move"
                        >
                          <div className="flex items-start justify-between">
                            <div className="flex items-start space-x-4">
                              {category.imageUrl && (
                                <img 
                                  src={category.imageUrl} 
                                  alt={category.name}
                                  className="w-20 h-20 object-cover rounded-md"
                                />
                              )}
                              <div>
                                <h4 className="text-sm font-medium text-gray-900">{category.name}</h4>
                                {category.shortDescription && (
                                  <p className="mt-1 text-sm text-gray-500">{category.shortDescription}</p>
                                )}
                              </div>
                            </div>
                            <div className="flex space-x-2">
                              <button
                                onClick={() => handleEditCategory(category)}
                                className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-primary hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                              >
                                Edit
                              </button>
                              <button
                                onClick={() => handleDeleteCategory(category.id, category.name, category.imageUrl)}
                                disabled={isDeleting}
                                className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 disabled:cursor-not-allowed"
                              >
                                {isDeleting ? 'Deleting...' : 'Delete'}
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
