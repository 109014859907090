import React, { createContext, useContext, useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

const BookingSystemContext = createContext();

export const useBookingSystem = () => {
  const context = useContext(BookingSystemContext);
  if (!context) {
    throw new Error('useBookingSystem must be used within a BookingSystemProvider');
  }
  return context;
};

export const BookingSystemProvider = ({ children }) => {
  const [isBookingSystemEnabled, setIsBookingSystemEnabled] = useState(false); // Default to false
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBookingSystemStatus = async () => {
      try {
        console.log('Fetching booking system status...');
        const docRef = doc(db, 'settings', 'bookingSystem');
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const enabled = docSnap.data().enabled;
          console.log('Booking system status fetched:', enabled);
          setIsBookingSystemEnabled(enabled);
        } else {
          console.log('No booking system settings found, initializing with default (disabled)');
          // Initialize with default settings if they don't exist
          await setDoc(docRef, { enabled: false });
          setIsBookingSystemEnabled(false);
        }
      } catch (error) {
        console.error('Error fetching booking system status:', error);
        // Default to disabled if there's an error
        setIsBookingSystemEnabled(false);
      } finally {
        setLoading(false);
      }
    };

    fetchBookingSystemStatus();
  }, []);

  const toggleBookingSystem = async (enabled) => {
    try {
      console.log('Toggling booking system to:', enabled);
      const docRef = doc(db, 'settings', 'bookingSystem');
      
      // Update Firestore first
      await setDoc(docRef, { enabled });
      
      // Verify the update was successful
      const updatedDoc = await getDoc(docRef);
      if (updatedDoc.exists() && updatedDoc.data().enabled === enabled) {
        console.log('Booking system successfully toggled to:', enabled);
        setIsBookingSystemEnabled(enabled);
      } else {
        console.error('Booking system update failed - Firestore state mismatch');
        throw new Error('Failed to update booking system state');
      }
    } catch (error) {
      console.error('Error toggling booking system:', error);
      // Revert the state if there's an error
      const docRef = doc(db, 'settings', 'bookingSystem');
      const currentDoc = await getDoc(docRef);
      if (currentDoc.exists()) {
        setIsBookingSystemEnabled(currentDoc.data().enabled);
      } else {
        // If document doesn't exist, default to disabled
        setIsBookingSystemEnabled(false);
      }
      throw error;
    }
  };

  if (loading) {
    return null; // or a loading spinner
  }

  return (
    <BookingSystemContext.Provider value={{ 
      isBookingSystemEnabled, 
      toggleBookingSystem,
      loading 
    }}>
      {children}
    </BookingSystemContext.Provider>
  );
};
