import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { httpsCallable } from 'firebase/functions';
import { collection, addDoc, doc, getDoc, deleteDoc } from 'firebase/firestore';
import { db, functions } from '../../config/firebase';
import { useBooking } from '../../context/BookingContext';
import { useBookingSystem } from '../../context/BookingSystemContext';
import { useAuth } from '../../context/AuthContext';
import { sendBookingConfirmation, sendEmailWithRetry } from '../../utils/emailService';
import LoadingOverlay from '../Common/LoadingOverlay';
import dayjs from 'dayjs';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  },
  hidePostalCode: true
};

// Force production environment for live site
const ENVIRONMENT = window.location.hostname === 'localhost' ? 'development' : 'production';

function PaymentStep({ 
  totalPrice,
  onBack,
  selectedServices,
  selectedDateTime,
  selectedLocation,
  user,
  getTotalDuration,
  isSubmitting: parentIsSubmitting,
  error: parentError
}) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { resetBooking } = useBooking();
  const { isBookingSystemEnabled } = useBookingSystem();
  const { isSuperAdmin } = useAuth();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [defaultDepositPercentage, setDefaultDepositPercentage] = useState(50);
  const [autoConfirm, setAutoConfirm] = useState(false);
  
  useEffect(() => {
    const fetchBookingSettings = async () => {
      try {
        const settingsDoc = await getDoc(doc(db, 'settings', 'booking'));
        if (settingsDoc.exists()) {
          const data = settingsDoc.data();
          if (data.depositPercentage !== undefined) {
            setDefaultDepositPercentage(data.depositPercentage);
          }
          if (data.autoConfirm !== undefined) {
            setAutoConfirm(data.autoConfirm);
          }
        }
      } catch (error) {
        console.error('Error fetching booking settings:', error);
      }
    };

    fetchBookingSettings();
  }, []);

  // Calculate deposit amount based on service-specific settings
  const calculateDepositAmount = () => {
    let totalDeposit = 0;
    
    selectedServices.forEach(service => {
      const servicePrice = service.price;
      let depositPercentage;

      if (service.requireFullPayment) {
        depositPercentage = 100;
      } else if (service.depositPercentage) {
        depositPercentage = service.depositPercentage;
      } else {
        depositPercentage = defaultDepositPercentage;
      }

      const serviceDeposit = Math.floor(servicePrice * depositPercentage) / 100;
      totalDeposit += serviceDeposit;
    });

    return totalDeposit;
  };

  const depositAmount = calculateDepositAmount();
  const isFullPayment = depositAmount === totalPrice;

  const sendConfirmationEmail = async (bookingId) => {
    try {
      const emailData = {
        customerName: user.displayName || user.email.split('@')[0],
        date: dayjs(selectedDateTime).format('MMMM D, YYYY'),
        time: dayjs(selectedDateTime).format('HH:mm'),
        serviceName: selectedServices.map(service => {
          let name = service.title;
          if (service.selectedVariation) {
            name += ` (${service.selectedVariation.name})`;
          }
          return name;
        }).join(', '),
        totalAmount: totalPrice,
        depositAmount: depositAmount,
        bookingId: bookingId,
        customerEmail: user.email
      };

      await sendEmailWithRetry(sendBookingConfirmation, emailData);
      console.log('Confirmation email sent successfully');
    } catch (error) {
      console.error('Error sending confirmation email:', error);
      // Don't throw the error as we don't want to affect the booking process
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!stripe || !elements) {
      return;
    }

    if (!isBookingSystemEnabled && !isSuperAdmin) {
      setError('The booking system is currently unavailable. Please try again later.');
      return;
    }

    setProcessing(true);
    setError(null);

    let tempBookingRef = null;

    try {
      console.log('Creating temporary booking with autoConfirm:', autoConfirm);
      console.log('Using environment:', ENVIRONMENT);
      
      const bookingData = {
        userId: user.uid,
        userEmail: user.email,
        userName: user.displayName,
        services: selectedServices.map(service => ({
          id: service.id,
          title: service.title,
          price: service.price,
          bookingLength: service.bookingLength,
          depositPercentage: service.requireFullPayment ? 100 : (service.depositPercentage || defaultDepositPercentage),
          hasVariations: service.hasVariations || false,
          selectedVariation: service.selectedVariation ? {
            name: service.selectedVariation.name,
            price: service.selectedVariation.price,
            description: service.selectedVariation.description
          } : null
        })),
        dateTime: selectedDateTime,
        locationId: selectedLocation.id,
        locationName: selectedLocation.name,
        totalPrice: totalPrice,
        depositAmount: depositAmount,
        isFullPayment: isFullPayment,
        totalDuration: getTotalDuration(),
        status: 'awaiting_payment',
        createdAt: new Date(),
        isTrustedUser: false,
        autoConfirm: autoConfirm
      };

      tempBookingRef = await addDoc(collection(db, 'bookings_temp'), bookingData);
      console.log('Temporary booking created:', tempBookingRef.id);

      try {
        console.log('Creating payment intent with autoConfirm:', autoConfirm);
        const createPaymentIntent = httpsCallable(functions, 'createPaymentIntent');
        
        const { data: { clientSecret, bookingId } } = await createPaymentIntent({
          amount: depositAmount,
          bookingId: tempBookingRef.id,
          userEmail: user.email,
          autoConfirm: autoConfirm,
          environment: ENVIRONMENT
        }).catch(error => {
          console.error('Payment intent creation failed:', {
            code: error.code,
            message: error.message,
            details: error.details
          });
          throw error;
        });

        if (!clientSecret) {
          throw new Error('Failed to create payment intent');
        }

        console.log('Confirming card payment...');
        const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: {
              card: elements.getElement(CardElement),
            },
          }
        );

        if (stripeError) {
          throw stripeError;
        }

        if (paymentIntent.status === 'succeeded') {
          console.log('Payment succeeded');
          
          // Send confirmation email if autoConfirm is enabled
          if (autoConfirm) {
            await sendConfirmationEmail(bookingId);
          }
          
          // Reset booking state
          resetBooking();
          
          // Navigate to confirmation page with booking ID
          navigate('/booking-confirmation', { 
            state: { bookingId }
          });
        } else {
          throw new Error('Payment failed');
        }
      } catch (paymentError) {
        console.error('Payment processing error:', paymentError);
        if (paymentError.code) {
          switch (paymentError.code) {
            case 'functions/invalid-argument':
              throw new Error('Invalid payment information provided.');
            case 'functions/internal':
              throw new Error('An internal error occurred. Please try again.');
            case 'functions/unauthenticated':
              throw new Error('Please sign in to complete the payment.');
            case 'functions/not-found':
              throw new Error('Booking information not found. Please try again.');
            default:
              throw paymentError;
          }
        }
        throw paymentError;
      }
    } catch (err) {
      console.error('Error details:', err);
      setError(err.message || 'An error occurred while processing your payment. Please try again.');
      
      if (tempBookingRef) {
        try {
          await deleteDoc(tempBookingRef);
        } catch (deleteErr) {
          console.error('Error deleting temporary booking:', deleteErr);
        }
      }
    } finally {
      setProcessing(false);
    }
  };

  if (!isBookingSystemEnabled && !isSuperAdmin) {
    return (
      <div className="max-w-3xl mx-auto">
        <div className="bg-white rounded-lg shadow p-6 text-center">
          <h2 className="text-2xl font-semibold mb-4">Online Booking Temporarily Unavailable</h2>
          <p className="text-gray-600 mb-4">
            We're currently updating our booking system to serve you better.
          </p>
          <p className="text-gray-600">
            Please check back again soon. Thank you for your patience.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto relative">
      {processing && <LoadingOverlay />}
      <div className="flex justify-between items-center mb-8">
        <button
          onClick={onBack}
          className="text-gray-600 hover:text-gray-800 flex items-center"
          disabled={processing}
        >
          <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
          Back
        </button>
      </div>
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-2xl font-semibold mb-6">Secure Your Booking</h2>
        <div className="mb-6">
          <p className="text-gray-600">
            {isFullPayment ? (
              `Full payment of £${depositAmount.toFixed(2)} is required to complete your booking.`
            ) : (
              `A deposit of £${depositAmount.toFixed(2)} is required to secure your booking.
              The remaining balance of £${(totalPrice - depositAmount).toFixed(2)} will be due at your appointment.`
            )}
          </p>
          
          {selectedServices.length > 0 && (
            <div className="mt-4 text-sm text-gray-500">
              <p className="font-medium mb-2">Payment breakdown:</p>
              {selectedServices.map((service, index) => {
                const serviceDepositPercentage = service.requireFullPayment ? 100 : (service.depositPercentage || defaultDepositPercentage);
                const serviceDeposit = Math.floor(service.price * serviceDepositPercentage) / 100;
                return (
                  <p key={index}>
                    {service.title}
                    {service.selectedVariation && (
                      <span className="text-gray-400">
                        {' '}({service.selectedVariation.name})
                      </span>
                    )}: {serviceDepositPercentage}% deposit (£{serviceDeposit.toFixed(2)})
                  </p>
                );
              })}
            </div>
          )}
        </div>
        
        {(error || parentError) && (
          <div className="mb-6 p-4 bg-red-50 border border-red-200 text-red-600 rounded-lg">
            {error || parentError}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Card Details
            </label>
            <div className="border rounded-md p-3">
              <CardElement options={CARD_ELEMENT_OPTIONS} />
            </div>
          </div>

          <button
            type="submit"
            disabled={!stripe || processing}
            className={`w-full bg-black text-white px-6 py-3 rounded-lg transition-colors ${
              (!stripe || processing) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-black/90'
            }`}
          >
            {processing ? 'Processing...' : isFullPayment ? 
              `Pay £${depositAmount.toFixed(2)}` : 
              `Pay £${depositAmount.toFixed(2)} Deposit`}
          </button>
        </form>
      </div>
    </div>
  );
}

export default PaymentStep;
