import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useMaintenance } from '../../context/MaintenanceContext';
import { useBookingSystem } from '../../context/BookingSystemContext';

const SuperAdminSettings = () => {
  const { isSuperAdmin } = useAuth();
  const { isMaintenanceMode, toggleMaintenanceMode } = useMaintenance();
  const { isBookingSystemEnabled, toggleBookingSystem, loading } = useBookingSystem();
  const [error, setError] = useState(null);
  const [isToggling, setIsToggling] = useState(false);

  if (!isSuperAdmin) {
    return null;
  }

  const handleToggleBookingSystem = async () => {
    try {
      setIsToggling(true);
      setError(null);
      await toggleBookingSystem(!isBookingSystemEnabled);
    } catch (err) {
      setError('Failed to toggle booking system. Please try again.');
      console.error('Error toggling booking system:', err);
    } finally {
      setIsToggling(false);
    }
  };

  if (loading) {
    return (
      <div className="bg-white shadow overflow-hidden sm:rounded-lg p-6">
        <div className="text-center text-gray-600">
          Loading settings...
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2 className="text-lg leading-6 font-medium text-gray-900">
          SuperAdmin Settings
        </h2>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Control site-wide maintenance mode and booking system
        </p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 space-y-6">
        {/* Maintenance Mode Section */}
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-900">Maintenance Mode</p>
            <p className="text-sm text-gray-500">
              When enabled, only superadmins can access the site
            </p>
          </div>
          <button
            onClick={() => toggleMaintenanceMode(!isMaintenanceMode)}
            className={`px-4 py-2 rounded-md text-white font-medium ${
              isMaintenanceMode 
                ? 'bg-red-600 hover:bg-red-700' 
                : 'bg-green-600 hover:bg-green-700'
            }`}
          >
            {isMaintenanceMode ? 'Disable' : 'Enable'} Maintenance Mode
          </button>
        </div>
        {isMaintenanceMode && (
          <div className="p-4 bg-yellow-50 rounded-md">
            <p className="text-sm text-yellow-700">
              ⚠️ Maintenance mode is currently active. Only superadmins can access the site.
            </p>
          </div>
        )}

        {/* Booking System Section */}
        <div className="pt-6 border-t border-gray-200">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-900">Booking System</p>
              <p className="text-sm text-gray-500">
                Control the availability of the entire booking system
              </p>
            </div>
            <button
              onClick={handleToggleBookingSystem}
              disabled={isToggling}
              className={`px-4 py-2 rounded-md text-white font-medium ${
                isBookingSystemEnabled 
                  ? 'bg-red-600 hover:bg-red-700' 
                  : 'bg-green-600 hover:bg-green-700'
              } ${isToggling ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {isToggling ? 'Updating...' : 
                isBookingSystemEnabled ? 'Disable' : 'Enable'} Booking System
            </button>
          </div>
          {!isBookingSystemEnabled && (
            <div className="mt-4 p-4 bg-yellow-50 rounded-md">
              <p className="text-sm text-yellow-700">
                ⚠️ The booking system is currently disabled. Customers cannot make new bookings.
              </p>
            </div>
          )}
          {error && (
            <div className="mt-4 p-4 bg-red-50 rounded-md">
              <p className="text-sm text-red-700">
                {error}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuperAdminSettings;
