import React from 'react';

const VariationForm = ({ variationForm, onInputChange, onAdd, onCancel }) => {
  return (
    <div className="bg-gray-50 p-4 rounded-lg space-y-4">
      <div>
        <label htmlFor="variationName" className="block text-sm font-medium text-gray-700">
          Variation Name
        </label>
        <input
          type="text"
          id="variationName"
          name="name"
          value={variationForm.name}
          onChange={onInputChange}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        />
      </div>
      <div>
        <label htmlFor="variationPrice" className="block text-sm font-medium text-gray-700">
          Price (£)
        </label>
        <input
          type="text"
          id="variationPrice"
          name="price"
          value={variationForm.price}
          onChange={onInputChange}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Duration
        </label>
        <div className="mt-1 grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="variationBookingLengthHours" className="block text-sm text-gray-500">
              Hours
            </label>
            <input
              type="number"
              name="bookingLengthHours"
              id="variationBookingLengthHours"
              min="0"
              value={variationForm.bookingLengthHours}
              onChange={onInputChange}
              className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="variationBookingLengthMinutes" className="block text-sm text-gray-500">
              Minutes
            </label>
            <input
              type="number"
              name="bookingLengthMinutes"
              id="variationBookingLengthMinutes"
              min="0"
              max="59"
              value={variationForm.bookingLengthMinutes}
              onChange={onInputChange}
              className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </div>
        </div>
      </div>
      <div>
        <label htmlFor="variationDescription" className="block text-sm font-medium text-gray-700">
          Description (optional)
        </label>
        <input
          type="text"
          id="variationDescription"
          name="description"
          value={variationForm.description}
          onChange={onInputChange}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        />
      </div>
      <div className="flex space-x-2">
        <button
          type="button"
          onClick={onAdd}
          className="flex-1 inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary/90"
        >
          Add
        </button>
        <button
          type="button"
          onClick={onCancel}
          className="flex-1 inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default VariationForm;
