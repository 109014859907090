import React from 'react';
import dayjs from 'dayjs';

const BlockedTimesSection = ({
  blockedTimes,
  newBlockedTime,
  onNewBlockedTimeChange,
  onAddBlockedTime,
  onRemoveBlockedTime,
  conflictError
}) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-4">Blocked Times</h3>
      <div className="flex space-x-4 mb-4">
        <input
          type="date"
          value={newBlockedTime.date}
          onChange={(e) => onNewBlockedTimeChange({ ...newBlockedTime, date: e.target.value })}
          className="form-input"
        />
        <input
          type="time"
          value={newBlockedTime.startTime}
          onChange={(e) => onNewBlockedTimeChange({ ...newBlockedTime, startTime: e.target.value })}
          className="form-input"
        />
        <select
          value={newBlockedTime.duration}
          onChange={(e) => onNewBlockedTimeChange({ ...newBlockedTime, duration: e.target.value })}
          className="form-select"
        >
          <option value="30">30 minutes</option>
          <option value="60">1 hour</option>
          <option value="120">2 hours</option>
          <option value="180">3 hours</option>
          <option value="240">4 hours</option>
          <option value="300">5 hours</option>
          <option value="360">6 hours</option>
          <option value="420">7 hours</option>
          <option value="480">8 hours</option>
        </select>
        <button
          onClick={onAddBlockedTime}
          className="button-secondary"
        >
          Add Blocked Time
        </button>
      </div>
      {conflictError && (
        <div className="mb-4 p-3 bg-red-50 border border-red-200 text-red-800 rounded">
          {conflictError}
        </div>
      )}
      <div className="space-y-2">
        {blockedTimes.map(block => (
          <div key={block.id} className="flex items-center justify-between bg-gray-50 p-2 rounded">
            <span>
              {new Date(block.date).toLocaleDateString()} at {block.startTime} 
              ({parseInt(block.duration) / 60} hours)
            </span>
            <button
              onClick={() => onRemoveBlockedTime(block.id)}
              className="text-red-600 hover:text-red-800"
            >
              Remove
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlockedTimesSection;
