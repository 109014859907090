import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ManageServices from '../components/Admin/ManageServices';
import ViewBookings from '../components/Admin/ViewBookings';
import BookingSettings from '../components/Admin/BookingSettings';
import SuperAdminSettings from '../components/Admin/SuperAdminSettings';
import EmailTest from '../components/EmailTemplates/EmailTest';
import ManageUsers from '../components/Admin/ManageUsers';
import ManageLocations from '../components/Admin/ManageLocations';
import AddCategory from '../components/Admin/AddCategory';
import { useAuth } from '../context/AuthContext';

const Admin = () => {
  const [activeTab, setActiveTab] = useState('bookings');
  const { isSuperAdmin } = useAuth();
  const debugEmailsEnabled = process.env.REACT_APP_DEBUG_EMAILS === 'true';

  // Debug log
  useEffect(() => {
    console.log('REACT_APP_DEBUG_EMAILS:', process.env.REACT_APP_DEBUG_EMAILS);
    console.log('debugEmailsEnabled:', debugEmailsEnabled);
  }, [debugEmailsEnabled]);

  const renderContent = () => {
    switch (activeTab) {
      case 'bookings':
        return <ViewBookings />;
      case 'services':
        return <ManageServices />;
      case 'categories':
        return <AddCategory />;
      case 'locations':
        return <ManageLocations />;
      case 'users':
        return <ManageUsers />;
      case 'bookingSettings':
        return <BookingSettings />;
      case 'superAdmin':
        return <SuperAdminSettings />;
      default:
        return <ViewBookings />;
    }
  };

  return (
    <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4 sm:mb-8">
        <h1 className="text-2xl sm:text-3xl font-semibold mb-4 md:mb-0">Admin Dashboard</h1>
        <Link
          to="/"
          className="text-primary hover:text-primary-dark transition-colors"
        >
          Return to Website
        </Link>
      </div>

      <div className="mb-4 sm:mb-8 overflow-x-auto">
        <div className="border-b border-gray-200 min-w-max">
          <nav className="-mb-px flex space-x-2 sm:space-x-4">
            {/* Bookings Section */}
            <button
              onClick={() => setActiveTab('bookings')}
              className={`${
                activeTab === 'bookings'
                  ? 'border-primary text-primary'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-2 sm:py-4 px-1 border-b-2 font-medium text-xs sm:text-sm`}
            >
              View Bookings
            </button>

            {/* Service Management */}
            <button
              onClick={() => setActiveTab('services')}
              className={`${
                activeTab === 'services'
                  ? 'border-primary text-primary'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-2 sm:py-4 px-1 border-b-2 font-medium text-xs sm:text-sm`}
            >
              Manage Services
            </button>

            <button
              onClick={() => setActiveTab('categories')}
              className={`${
                activeTab === 'categories'
                  ? 'border-primary text-primary'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-2 sm:py-4 px-1 border-b-2 font-medium text-xs sm:text-sm`}
            >
              Manage Categories
            </button>

            <button
              onClick={() => setActiveTab('locations')}
              className={`${
                activeTab === 'locations'
                  ? 'border-primary text-primary'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-2 sm:py-4 px-1 border-b-2 font-medium text-xs sm:text-sm`}
            >
              Manage Locations
            </button>

            {/* User Management */}
            <button
              onClick={() => setActiveTab('users')}
              className={`${
                activeTab === 'users'
                  ? 'border-primary text-primary'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-2 sm:py-4 px-1 border-b-2 font-medium text-xs sm:text-sm`}
            >
              Manage Users
            </button>

            {/* Settings */}
            <button
              onClick={() => setActiveTab('bookingSettings')}
              className={`${
                activeTab === 'bookingSettings'
                  ? 'border-primary text-primary'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-2 sm:py-4 px-1 border-b-2 font-medium text-xs sm:text-sm`}
            >
              Booking Settings
            </button>

            {/* SuperAdmin Settings - Only visible to superadmin */}
            {isSuperAdmin && (
              <button
                onClick={() => setActiveTab('superAdmin')}
                className={`${
                  activeTab === 'superAdmin'
                    ? 'border-primary text-primary'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } whitespace-nowrap py-2 sm:py-4 px-1 border-b-2 font-medium text-xs sm:text-sm`}
              >
                SuperAdmin
              </button>
            )}
          </nav>
        </div>
      </div>

      <div className="overflow-x-auto">
        {renderContent()}
      </div>
    </div>
  );
};

export default Admin;
