import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { db } from '../../../config/firebase';
import { doc, updateDoc } from 'firebase/firestore';
import DateTimeSelection from '../../Booking/DateTimeSelection';
import { sendBookingConfirmation, sendEmailWithRetry } from '../../../utils/emailService';

const BookingDetails = ({ booking, onClose, onBookingUpdate }) => {
  const [notes, setNotes] = useState('');
  const [saving, setSaving] = useState(false);
  const [status, setStatus] = useState('');
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [isEditingDateTime, setIsEditingDateTime] = useState(false);

  useEffect(() => {
    if (booking) {
      setNotes(booking.adminNotes ?? '');
      setStatus(booking.status || '');
    }
  }, [booking]);

  const handleSaveNotes = async () => {
    if (!booking) return;
    
    try {
      setSaving(true);
      const bookingRef = doc(db, 'bookings', booking.id);
      await updateDoc(bookingRef, {
        adminNotes: notes
      });
      
      const updatedBooking = {
        ...booking,
        adminNotes: notes
      };
      
      if (onBookingUpdate) {
        onBookingUpdate(updatedBooking);
      }
    } catch (error) {
      console.error('Error saving notes:', error);
    } finally {
      setSaving(false);
    }
  };

  const sendConfirmationEmail = async (bookingData) => {
    try {
      const emailData = {
        customerName: bookingData.userName || bookingData.userEmail?.split('@')[0] || 'Customer',
        date: dayjs(bookingData.dateTime).format('MMMM D, YYYY'),
        time: dayjs(bookingData.dateTime).format('HH:mm'),
        serviceName: bookingData.services.map(service => {
          let name = service.title;
          if (service.selectedVariation) {
            name += ` (${service.selectedVariation.name})`;
          }
          return name;
        }).join(', '),
        totalAmount: bookingData.totalPrice,
        depositAmount: bookingData.depositAmount,
        bookingId: bookingData.id,
        customerEmail: bookingData.userEmail
      };

      if (bookingData.userEmail) {
        await sendEmailWithRetry(sendBookingConfirmation, emailData);
      }
    } catch (error) {
      console.error('Error sending confirmation email:', error);
    }
  };

  const handleStatusChange = async (newStatus) => {
    if (!booking?.id || status === newStatus) return;

    try {
      setUpdatingStatus(true);
      const bookingRef = doc(db, 'bookings', booking.id);
      const updatedAt = new Date();
      
      await updateDoc(bookingRef, {
        status: newStatus,
        updatedAt
      });

      const updatedBooking = {
        ...booking,
        status: newStatus,
        updatedAt
      };

      setStatus(newStatus);
      
      if (newStatus === 'confirmed' && booking.userEmail) {
        await sendConfirmationEmail({
          ...updatedBooking,
          id: booking.id
        });
      }

      if (onBookingUpdate) {
        onBookingUpdate(updatedBooking);
      }

    } catch (error) {
      console.error('Error updating booking status:', error);
    } finally {
      setUpdatingStatus(false);
    }
  };

  const handleTimeSlotSelect = async (newDateTime, location) => {
    if (!booking?.id) return;

    try {
      setSaving(true);
      const bookingRef = doc(db, 'bookings', booking.id);
      
      await updateDoc(bookingRef, {
        dateTime: newDateTime,
        locationId: location.id,
        locationName: location.name,
        updatedAt: new Date()
      });

      const updatedBooking = {
        ...booking,
        dateTime: newDateTime,
        locationId: location.id,
        locationName: location.name,
        updatedAt: new Date()
      };

      if (onBookingUpdate) {
        onBookingUpdate(updatedBooking);
      }

      setIsEditingDateTime(false);
    } catch (error) {
      console.error('Error updating booking date/time:', error);
    } finally {
      setSaving(false);
    }
  };

  if (!booking) return null;

  const isFullPayment = booking.services?.every(service => service.depositPercentage === 100 || service.requireFullPayment);
  const isImported = !booking.userId;
  const totalDuration = booking.totalDuration || { hours: 0, minutes: 0 };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start sm:items-center justify-center z-50 p-0 sm:p-4">
      <div className="bg-white w-full sm:rounded-lg sm:max-w-2xl h-full sm:h-auto overflow-y-auto">
        <div className="sticky top-0 bg-white border-b border-gray-200 px-4 py-3 sm:px-6 flex justify-between items-center">
          <div>
            <h3 className="text-base sm:text-lg font-medium text-gray-900">
              Booking Details
            </h3>
            {isImported && (
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800 mt-1">
                Imported Booking
              </span>
            )}
          </div>
          <button
            onClick={onClose}
            className="p-2 text-gray-400 hover:text-gray-500"
            aria-label="Close"
          >
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        
        <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">
          {/* Status Section */}
          <div className="bg-gray-50 p-3 sm:p-4 rounded-lg">
            <div className="flex flex-col sm:flex-row sm:items-center justify-between space-y-2 sm:space-y-0">
              <div>
                <p className="text-sm font-medium text-gray-500">Current Status</p>
                <p className={`mt-1 text-sm font-semibold ${
                  status === 'confirmed' ? 'text-green-600' :
                  status === 'pending' ? 'text-yellow-600' :
                  status === 'rejected' ? 'text-red-600' :
                  'text-gray-600'
                }`}>
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </p>
              </div>
              <select
                value={status}
                onChange={(e) => handleStatusChange(e.target.value)}
                disabled={updatingStatus}
                className="block w-full sm:w-40 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary text-sm rounded-md"
              >
                <option value="pending">Pending</option>
                <option value="confirmed">Confirm</option>
                <option value="rejected">Reject</option>
                <option value="cancelled">Cancel</option>
              </select>
            </div>
          </div>

          {isEditingDateTime ? (
            <div className="bg-white rounded-lg">
              <div className="flex justify-between items-center mb-4">
                <h4 className="text-base sm:text-lg font-medium">Select New Date & Time</h4>
                <button
                  onClick={() => setIsEditingDateTime(false)}
                  className="p-2 text-gray-400 hover:text-gray-500"
                >
                  Cancel
                </button>
              </div>
              <DateTimeSelection
                selectedServices={booking.services}
                onTimeSlotSelect={handleTimeSlotSelect}
              />
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <p className="text-sm font-medium text-gray-500">Date</p>
                <div className="flex items-center space-x-2 mt-1">
                  <p className="text-sm text-gray-900">{dayjs(booking.dateTime).format('MMMM D, YYYY')}</p>
                  <button
                    onClick={() => setIsEditingDateTime(true)}
                    className="text-primary hover:text-primary-dark text-sm p-1"
                  >
                    Edit
                  </button>
                </div>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-500">Time</p>
                <p className="text-sm text-gray-900 mt-1">{dayjs(booking.dateTime).format('HH:mm')}</p>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-500">Location</p>
                <p className="text-sm text-gray-900 mt-1">{booking.locationName}</p>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-500">Customer</p>
                <p className="text-sm text-gray-900 mt-1 break-words">{booking.userEmail || 'No email available'}</p>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-500">Total Duration</p>
                <p className="text-sm text-gray-900 mt-1">
                  {totalDuration.hours}h {totalDuration.minutes}m
                </p>
              </div>
            </div>
          )}

          <div>
            <p className="text-sm font-medium text-gray-500 mb-2">Services</p>
            <div className="bg-gray-50 rounded-lg p-3 sm:p-4">
              <div className="space-y-3">
                {booking.services?.map((service, index) => (
                  <div key={index} className="pb-2 border-b border-gray-200 last:border-0 last:pb-0">
                    <div className="flex justify-between text-sm">
                      <div className="pr-2">
                        <span className="text-gray-900">{service.title}</span>
                        {service.selectedVariation && (
                          <span className="text-gray-500 ml-1">
                            ({service.selectedVariation.name})
                          </span>
                        )}
                      </div>
                      <span className="text-gray-600 whitespace-nowrap">£{service.price}</span>
                    </div>
                    <div className="text-xs text-gray-500 mt-1">
                      {isImported ? (
                        <a 
                          href="https://partners.fresha.com" 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="text-primary hover:text-primary-dark"
                        >
                          Check Fresha
                        </a>
                      ) : (
                        <>
                          {service.requireFullPayment ? 'Full payment required' :
                           service.depositPercentage ? `${service.depositPercentage}% deposit required` :
                           'Default deposit rate'}
                        </>
                      )}
                      {service.selectedVariation?.description && !isImported && (
                        <span className="block sm:inline sm:ml-2">• {service.selectedVariation.description}</span>
                      )}
                      {service.bookingLength && (
                        <span className="block sm:inline sm:ml-2">• Duration: {service.bookingLength.hours}h {service.bookingLength.minutes}m</span>
                      )}
                    </div>
                  </div>
                ))}
                <div className="pt-2 border-t border-gray-200 flex justify-between text-sm font-medium">
                  <span className="text-gray-900">Total Price</span>
                  <span className="text-gray-900">£{booking.totalPrice}</span>
                </div>
                {!isImported && booking.payment && (
                  <div className="pt-2 border-t border-gray-200 space-y-2">
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-900">
                        {isFullPayment ? 'Payment Amount' : 'Required Deposit'}
                      </span>
                      <span className="text-gray-900">£{(booking.payment.amount).toFixed(2)}</span>
                    </div>
                    {!isFullPayment && (
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-900">Remaining Balance</span>
                        <span className="text-gray-900">£{(booking.totalPrice - booking.payment.amount).toFixed(2)}</span>
                      </div>
                    )}
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-900">Payment Status</span>
                      <span className={`font-medium ${booking.payment.status === 'completed' ? 'text-green-600' : 'text-yellow-600'}`}>
                        {booking.payment.status === 'completed' ? 'Paid' : 'Pending'}
                      </span>
                    </div>
                    {booking.payment.status === 'completed' && booking.payment.created && (
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-900">Paid On</span>
                        <span className="text-gray-600">
                          {dayjs(booking.payment.created.toDate()).format('MMM D, YYYY HH:mm')}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div>
            <p className="text-sm font-medium text-gray-500 mb-2">Notes</p>
            <div className="mt-1">
              <textarea
                rows={4}
                className="shadow-sm block w-full focus:ring-primary focus:border-primary sm:text-sm border border-gray-300 rounded-md"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Add notes about this booking..."
              />
            </div>
            <div className="mt-2 flex justify-end">
              <button
                onClick={handleSaveNotes}
                disabled={saving}
                className={`w-full sm:w-auto inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary ${
                  saving ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {saving ? 'Saving...' : 'Save Notes'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;
