import React, { createContext, useContext, useState, useEffect } from 'react';
import { db } from '../config/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';

const MaintenanceContext = createContext();

export const useMaintenance = () => {
  return useContext(MaintenanceContext);
};

export const MaintenanceProvider = ({ children }) => {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMaintenanceStatus = async () => {
      try {
        const maintenanceDoc = await getDoc(doc(db, 'settings', 'maintenance'));
        if (maintenanceDoc.exists()) {
          setIsMaintenanceMode(maintenanceDoc.data().enabled || false);
        }
      } catch (error) {
        console.error('Error fetching maintenance status:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMaintenanceStatus();
  }, []);

  const toggleMaintenanceMode = async (enabled) => {
    try {
      await setDoc(doc(db, 'settings', 'maintenance'), {
        enabled,
        lastUpdated: new Date().toISOString()
      });
      setIsMaintenanceMode(enabled);
    } catch (error) {
      console.error('Error toggling maintenance mode:', error);
      throw error;
    }
  };

  const value = {
    isMaintenanceMode,
    toggleMaintenanceMode,
    loading
  };

  return (
    <MaintenanceContext.Provider value={value}>
      {!loading && children}
    </MaintenanceContext.Provider>
  );
};
