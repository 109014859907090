import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../config/firebase';
import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import { useBooking } from '../context/BookingContext';
import { useBookingSystem } from '../context/BookingSystemContext';
import { useAuth } from '../context/AuthContext';
import VariationModal from '../components/Common/VariationModal';

const ServiceCategory = () => {
  const { category } = useParams();
  const [categoryData, setCategoryData] = useState(null);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showVariationModal, setShowVariationModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const navigate = useNavigate();
  const { toggleService, setSelectedCategory } = useBooking();
  const { isBookingSystemEnabled } = useBookingSystem();
  const { isSuperAdmin } = useAuth();

  const canBook = isBookingSystemEnabled || isSuperAdmin;

  useEffect(() => {
    const fetchCategoryAndServices = async () => {
      try {
        const categoriesQuery = query(collection(db, 'categories'));
        const categorySnapshot = await getDocs(categoriesQuery);
        const matchingCategory = categorySnapshot.docs.find(doc => 
          doc.data().name.toLowerCase() === decodeURIComponent(category).toLowerCase()
        );

        if (matchingCategory) {
          const categoryData = {
            id: matchingCategory.id,
            ...matchingCategory.data()
          };
          setCategoryData(categoryData);

          const servicesQuery = query(collection(db, 'services'), orderBy('order', 'asc'));
          const servicesSnapshot = await getDocs(servicesQuery);
          const servicesData = servicesSnapshot.docs
            .map(doc => ({
              id: doc.id,
              ...doc.data()
            }))
            .filter(service => 
              service.category.toLowerCase() === categoryData.name.toLowerCase() &&
              !service.hidden
            );
          setServices(servicesData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryAndServices();
  }, [category]);

  const handleBookNow = (service) => {
    if (service.hasVariations) {
      setSelectedService(service);
      setShowVariationModal(true);
    } else {
      toggleService(service);
      setSelectedCategory(categoryData.name.toLowerCase());
      navigate('/booking');
    }
  };

  const handleVariationSelect = (variation) => {
    const serviceWithVariation = {
      ...selectedService,
      selectedVariation: variation,
      price: variation.price
    };
    toggleService(serviceWithVariation);
    setSelectedCategory(categoryData.name.toLowerCase());
    setShowVariationModal(false);
    navigate('/booking');
  };

  const formatDuration = (service) => {
    if (!service) return '';

    if (service.hasVariations && service.variations?.length > 0) {
      const validDurations = service.variations
        .filter(v => v.bookingLength?.hours > 0 || v.bookingLength?.minutes > 0)
        .map(v => ({
          totalMinutes: ((v.bookingLength?.hours || 0) * 60) + (v.bookingLength?.minutes || 0)
        }));

      if (validDurations.length === 0) return '';

      const minDuration = Math.min(...validDurations.map(d => d.totalMinutes));
      const maxDuration = Math.max(...validDurations.map(d => d.totalMinutes));
      
      const minHours = Math.floor(minDuration / 60);
      const minMinutes = minDuration % 60;
      const maxHours = Math.floor(maxDuration / 60);
      const maxMinutes = maxDuration % 60;

      if (minDuration === maxDuration) {
        return `${minHours}h ${minMinutes}m`;
      }
      
      return `${minHours}h ${minMinutes}m - ${maxHours}h ${maxMinutes}m`;
    }
    
    const hours = service.bookingLength?.hours || 0;
    const minutes = service.bookingLength?.minutes || 0;
    return (hours > 0 || minutes > 0) ? `${hours}h ${minutes}m` : '';
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-white pt-32">
        <div className="text-xl text-gray-600">Loading...</div>
      </div>
    );
  }

  if (!categoryData) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-white pt-32">
        <div className="text-xl text-gray-600">Category not found</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      {/* Category Header */}
      <div className="relative h-[40vh] sm:h-[60vh] w-full mt-24">
        {categoryData.imageUrl && (
          <>
            <div 
              className="absolute inset-0 w-full h-full bg-cover bg-center"
              style={{
                backgroundImage: `url(${categoryData.imageUrl})`
              }}
            />
            <div className="absolute inset-0 bg-gradient-to-b from-primary/90 to-primary/70"></div>
            <div className="absolute inset-0 flex items-center justify-center px-4">
              <h1 className="text-3xl sm:text-4xl md:text-6xl font-secondary text-white tracking-[.25em] sm:tracking-[.5em] text-center">
                {categoryData.name.toUpperCase()}
              </h1>
            </div>
          </>
        )}
      </div>

      {/* Category Description */}
      {categoryData.description && (
        <div className="px-4 py-16 sm:py-32 text-center bg-primary">
          <div className="max-w-3xl mx-auto">
            <p className="font-primary text-base sm:text-lg text-white tracking-wide leading-relaxed">
              {categoryData.description}
            </p>
          </div>
        </div>
      )}

      {/* Services List */}
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {!canBook && (
          <div className="mb-8 p-4 bg-gray-50 rounded-lg text-center">
            <p className="text-gray-600">
              Online booking is temporarily unavailable. Please check back again soon.
            </p>
          </div>
        )}
        
        {services.map((service) => (
          <div key={service.id} className="mb-16 sm:mb-24">
            <div className="flex flex-col sm:flex-row sm:items-baseline sm:justify-between gap-4 sm:gap-8 mb-4 sm:mb-6">
              <h3 className="text-xl sm:text-2xl font-secondary text-primary">
                {service.title}
              </h3>
              <div className="flex items-center sm:items-baseline flex-row sm:flex-1">
                <div className="hidden sm:block border-b border-dotted border-primary flex-grow mx-4"></div>
                <div className="text-right">
                  <span className="text-xl sm:text-2xl font-secondary text-primary whitespace-nowrap">
                    {service.hasVariations && service.variations?.length > 0 ? (
                      `From £${Math.min(...service.variations.map(v => v.price))}`
                    ) : (
                      `£${service.price}`
                    )}
                  </span>
                  {formatDuration(service) && (
                    <div className="text-sm text-gray-500 mt-1">
                      {formatDuration(service)}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <p className="text-gray-600 font-primary mb-6 sm:mb-8 text-base sm:text-lg leading-relaxed">
              {service.briefDescription || service.description}
            </p>
            {canBook && (
              <button 
                className="w-full sm:w-auto px-8 py-3 bg-transparent border-2 border-primary text-primary hover:bg-primary hover:text-white transition-colors duration-200 rounded-lg text-sm sm:text-base font-medium tracking-wide touch-manipulation"
                onClick={() => handleBookNow(service)}
              >
                BOOK NOW
              </button>
            )}
          </div>
        ))}

        {services.length === 0 && (
          <div className="text-center text-gray-600 mt-8">
            No services available in this category yet.
          </div>
        )}
      </div>

      {showVariationModal && selectedService && (
        <VariationModal
          isOpen={showVariationModal}
          onClose={() => setShowVariationModal(false)}
          variations={selectedService.variations || []}
          onSelect={handleVariationSelect}
          title={`Select ${selectedService.title} Variation`}
        />
      )}
    </div>
  );
};

export default ServiceCategory;
