import React from 'react';

const DepositSection = ({ depositPercentage, onDepositChange }) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-4">Deposit Settings</h3>
      <div className="flex items-center space-x-4">
        <label className="text-sm font-medium text-gray-700">Required Deposit:</label>
        <input
          type="number"
          min="0"
          max="100"
          value={depositPercentage}
          onChange={(e) => onDepositChange(parseInt(e.target.value) || 0)}
          className="form-input w-24"
        />
        <span className="text-sm text-gray-600">% of total booking price</span>
      </div>
    </div>
  );
};

export default DepositSection;
