import React, { useState } from 'react';
import VariationModal from '../Common/VariationModal';

function ServiceList({ services, selectedServices, onServiceToggle, isBookingSystemEnabled, isSuperAdmin }) {
  const [selectedVariationService, setSelectedVariationService] = useState(null);

  const handleServiceClick = (service) => {
    if (!isBookingSystemEnabled && !isSuperAdmin) return;
    
    const isSelected = selectedServices.find(s => s.id === service.id);
    if (isSelected) {
      onServiceToggle(service);
      return;
    }
    
    if (service.hasVariations) {
      setSelectedVariationService(service);
    } else {
      onServiceToggle(service);
    }
  };

  const handleVariationSelect = (variation) => {
    if (!selectedVariationService) return;
    if (!isBookingSystemEnabled && !isSuperAdmin) return;
    
    const serviceWithVariation = {
      ...selectedVariationService,
      selectedVariation: variation,
      price: variation.price,
      bookingLength: variation.bookingLength
    };
    
    onServiceToggle(serviceWithVariation);
    setSelectedVariationService(null);
  };

  const formatDuration = (bookingLength) => {
    if (!bookingLength) return '';
    const hours = bookingLength.hours || 0;
    const minutes = bookingLength.minutes || 0;
    if (hours === 0 && minutes === 0) return '';
    return `${hours}h ${minutes}m`;
  };

  return (
    <div className="space-y-3 sm:space-y-4">
      {services.map(service => {
        const isSelected = selectedServices.find(s => s.id === service.id);
        const isDisabled = !isBookingSystemEnabled && !isSuperAdmin;
        return (
          <div
            key={service.id}
            onClick={() => handleServiceClick(service)}
            className={`transition-colors px-4 sm:px-6 py-4 -mx-4 sm:-mx-6 ${
              isSelected ? 'bg-primary text-white rounded-xl shadow-md' : 'hover:bg-gray-50 active:bg-gray-100'
            } ${isDisabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
          >
            <div className="flex flex-col sm:flex-row sm:items-center">
              <div className="flex-grow mb-2 sm:mb-0">
                <h3 className="font-['Bodoni_Classico'] text-lg sm:text-xl pr-4">
                  {service.title}
                  {isSelected && service.selectedVariation && (
                    <span className="ml-2 text-sm font-normal">
                      ({service.selectedVariation.name})
                    </span>
                  )}
                </h3>
                {service.hasVariations && !isSelected && !isDisabled && (
                  <div className={`text-sm mt-1 ${isSelected ? 'text-white/80' : 'text-gray-500'}`}>
                    Tap to select variation
                  </div>
                )}
              </div>
              
              <div className="flex items-center justify-between sm:justify-end w-full sm:w-auto">
                {/* Duration */}
                {(!service.hasVariations || (isSelected && service.selectedVariation)) && (
                  <span className={`text-sm sm:mr-28 w-20 text-right ${
                    isSelected ? 'text-white/80' : 'text-gray-500'
                  }`}>
                    {formatDuration(service.bookingLength)}
                  </span>
                )}
                {/* Spacer for variable services without selection */}
                {service.hasVariations && !isSelected && (
                  <span className="sm:mr-28 w-20"></span>
                )}
                
                {/* Price */}
                <div className="w-16 text-right">
                  {service.hasVariations ? (
                    <span className="text-sm">
                      From £{Math.min(...service.variations.map(v => v.price))}
                    </span>
                  ) : (
                    <span className="text-base sm:text-lg font-medium">
                      £{service.price}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <VariationModal
        isOpen={!!selectedVariationService}
        onClose={() => setSelectedVariationService(null)}
        variations={selectedVariationService?.variations || []}
        onSelect={handleVariationSelect}
        title={`Select ${selectedVariationService?.title} Variation`}
      />
    </div>
  );
}

export default ServiceList;
