import React from 'react';

const BookingStatusSection = ({ autoConfirm, onAutoConfirmChange }) => {
  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-4">Booking Status Settings</h3>
      <div className="space-y-4">
        <div className="flex items-center">
          <input
            type="checkbox"
            id="autoConfirm"
            checked={autoConfirm}
            onChange={(e) => onAutoConfirmChange(e.target.checked)}
            className="h-4 w-4 text-primary border-gray-300 rounded focus:ring-primary"
          />
          <label htmlFor="autoConfirm" className="ml-2 block text-sm text-gray-900">
            Automatically confirm new bookings
          </label>
        </div>
        <p className="text-sm text-gray-500">
          When enabled, new bookings will be automatically confirmed. When disabled, new bookings will be set to pending status and require manual confirmation.
        </p>
      </div>
    </div>
  );
};

export default BookingStatusSection;
