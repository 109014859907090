import React from 'react';

function CategoryFilter({ categories, selectedCategory, onCategorySelect }) {
  return (
    <div className="relative mb-8 sm:mb-20">
      <div className="relative">
        {/* Gradient fade indicators - positioned relative to the full viewport width */}
        <div className="absolute left-0 top-0 bottom-0 w-12 bg-gradient-to-r from-white via-white to-transparent z-10" />
        <div className="absolute right-0 top-0 bottom-0 w-12 bg-gradient-to-l from-white via-white to-transparent z-10" />
        
        {/* Scrollable container - negative margin to extend beyond parent padding */}
        <div className="relative -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="flex space-x-3 overflow-x-auto px-4 sm:px-6 lg:px-8 pb-4 scrollbar-hide scroll-smooth">
            {categories.map(category => (
              <button
                key={category.id}
                onClick={() => onCategorySelect(category.name.toLowerCase())}
                className={`px-6 py-3 sm:px-5 sm:py-1.5 rounded-full whitespace-nowrap text-sm sm:text-xs font-medium tracking-[0.15em] transition-colors duration-200 touch-manipulation ${
                  selectedCategory === category.name.toLowerCase()
                    ? 'bg-black text-white shadow-md'
                    : 'bg-gray-50 text-gray-900 hover:bg-gray-100 active:bg-gray-200'
                }`}
              >
                {category.name.toUpperCase()}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Scroll hint - only shown on mobile */}
      <div className="flex justify-center mt-2 sm:hidden">
        <div className="w-12 h-1 rounded-full bg-gray-200" />
      </div>
    </div>
  );
}

export default CategoryFilter;
