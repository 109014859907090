// Email service configuration
export const EMAILJS_CONFIG = {
  SERVICE_ID: 'service_80k443t',
  TEMPLATE_IDS: {
    BOOKING_CONFIRMATION: 'template_bvf0eet',
    PASSWORD_RESET: 'template_bvf0eet',
    WELCOME_EMAIL: 'template_bvf0eet',
    APPOINTMENT_REMINDER: 'template_bvf0eet',
    CANCELLATION: 'template_bvf0eet',
    CONTACT_FORM: 'template_qyvj2an'  // Added contact form template
  },
  PUBLIC_KEY: 'jTeMyfB2456RSKshX'
};
