import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../context/AuthContext';
import { useBooking } from '../context/BookingContext';
import { useBookingSystem } from '../context/BookingSystemContext';
import DateTimeSelection from '../components/Booking/DateTimeSelection';
import CategoryFilter from '../components/Booking/CategoryFilter';
import ServiceList from '../components/Booking/ServiceList';
import BookingSummary from '../components/Booking/BookingSummary';
import PaymentStep from '../components/Booking/PaymentStep';
import ConfirmationStep from '../components/Booking/ConfirmationStep';
import { sendBookingConfirmation, sendEmailWithRetry } from '../utils/emailService';
import dayjs from 'dayjs';

function Booking() {
  const [step, setStep] = useState(1);
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [autoConfirm, setAutoConfirm] = useState(false);
  const [defaultDepositPercentage, setDefaultDepositPercentage] = useState(50);
  
  const { user, isSuperAdmin, isTrustedUser } = useAuth();
  const { isBookingSystemEnabled } = useBookingSystem();
  const navigate = useNavigate();
  const {
    categories,
    services,
    selectedServices,
    selectedCategory,
    selectedLocation,
    loading,
    error: fetchError,
    setSelectedCategory,
    toggleService,
    getTotalDuration,
    getTotalPrice,
    resetBooking,
  } = useBooking();

  useEffect(() => {
    const fetchBookingSettings = async () => {
      try {
        const settingsDoc = await getDoc(doc(db, 'settings', 'booking'));
        if (settingsDoc.exists()) {
          const data = settingsDoc.data();
          setAutoConfirm(data.autoConfirm || false);
          setDefaultDepositPercentage(data.depositPercentage || 50);
        }
      } catch (error) {
        console.error('Error fetching booking settings:', error);
      }
    };

    fetchBookingSettings();
  }, []);

  const calculateDepositAmount = () => {
    let totalDeposit = 0;
    
    selectedServices.forEach(service => {
      const servicePrice = service.price;
      let depositPercentage;

      if (service.requireFullPayment) {
        depositPercentage = 100;
      } else if (service.depositPercentage) {
        depositPercentage = service.depositPercentage;
      } else {
        depositPercentage = defaultDepositPercentage;
      }

      const serviceDeposit = Math.floor(servicePrice * depositPercentage) / 100;
      totalDeposit += serviceDeposit;
    });

    return totalDeposit;
  };

  const handleContinue = () => {
    if (selectedServices.length > 0) {
      setStep(2);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleTimeSlotSelect = (dateTime) => {
    setSelectedDateTime(dateTime);
    if (!user) {
      navigate('/signin', { 
        state: { 
          returnTo: '/booking',
          bookingData: {
            services: selectedServices,
            dateTime: dateTime,
            locationId: selectedLocation?.id
          }
        } 
      });
    } else {
      setStep(isTrustedUser ? 3 : 4);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleBack = () => {
    setStep(prev => prev - 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const sendConfirmationEmail = async (bookingId) => {
    try {
      const totalPrice = getTotalPrice();
      const depositAmount = calculateDepositAmount();

      const emailData = {
        customerName: user.displayName || user.email.split('@')[0],
        date: dayjs(selectedDateTime).format('MMMM D, YYYY'),
        time: dayjs(selectedDateTime).format('HH:mm'),
        serviceName: selectedServices.map(service => {
          let name = service.title;
          if (service.selectedVariation) {
            name += ` (${service.selectedVariation.name})`;
          }
          return name;
        }).join(', '),
        totalAmount: totalPrice,
        depositAmount: depositAmount,
        bookingId: bookingId,
        customerEmail: user.email
      };

      await sendEmailWithRetry(sendBookingConfirmation, emailData);
    } catch (error) {
      console.error('Error sending confirmation email:', error);
    }
  };

  const handleTrustedUserBooking = async () => {
    try {
      setIsSubmitting(true);
      setError(null);

      const totalPrice = getTotalPrice();
      const depositAmount = calculateDepositAmount();

      const bookingData = {
        userId: user.uid,
        userEmail: user.email,
        services: selectedServices.map(service => ({
          id: service.id,
          title: service.title,
          price: service.price,
          bookingLength: service.bookingLength,
          depositPercentage: service.requireFullPayment ? 100 : (service.depositPercentage || defaultDepositPercentage),
          hasVariations: service.hasVariations || false,
          selectedVariation: service.selectedVariation ? {
            name: service.selectedVariation.name,
            price: service.selectedVariation.price,
            description: service.selectedVariation.description
          } : null
        })),
        dateTime: selectedDateTime,
        locationId: selectedLocation.id,
        locationName: selectedLocation.name,
        totalPrice: totalPrice,
        depositAmount: depositAmount,
        totalDuration: getTotalDuration(),
        status: autoConfirm ? 'confirmed' : 'pending',
        createdAt: new Date(),
        isTrustedUser: true,
        autoConfirm: autoConfirm
      };

      const docRef = await addDoc(collection(db, 'bookings'), bookingData);

      if (autoConfirm) {
        await sendConfirmationEmail(docRef.id);
      }

      resetBooking();

      navigate('/booking-confirmation', { 
        state: { 
          bookingId: docRef.id,
          bookingData
        }
      });
    } catch (error) {
      console.error('Error creating booking:', error);
      setError('Failed to create booking. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-white pt-32">
        <div className="text-xl text-gray-600">Loading...</div>
      </div>
    );
  }

  if (fetchError) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-white pt-32">
        <div className="text-xl text-red-600">{fetchError}</div>
      </div>
    );
  }

  if (!isBookingSystemEnabled && !isSuperAdmin) {
    return (
      <div className="min-h-screen bg-white pt-32">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="text-center">
            <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4">
              Online Booking Temporarily Unavailable
            </h2>
            <p className="text-base sm:text-lg text-gray-600 mb-4">
              We're currently updating our booking system to serve you better.
            </p>
            <p className="text-base sm:text-lg text-gray-600">
              Please check back again soon. Thank you for your patience.
            </p>
            <div className="mt-8 p-4 bg-gray-50 rounded-lg inline-block">
              <p className="text-gray-600">
                Booking is not currently possible. Please try again later.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white pt-32">
      <div className="max-w-7xl mx-auto">
        {step === 1 && (
          <>
            <div className="px-4 sm:px-6 lg:px-8">
              <h1 className="text-2xl sm:text-4xl font-bold text-gray-900 mb-6 sm:mb-8">
                Select services
              </h1>
            </div>

            {/* Remove padding from container to allow gradient to extend */}
            <div className="overflow-hidden">
              <CategoryFilter
                categories={categories}
                selectedCategory={selectedCategory}
                onCategorySelect={setSelectedCategory}
              />
            </div>

            <div className="px-4 sm:px-6 lg:px-8 mb-32">
              <ServiceList
                services={services}
                selectedServices={selectedServices}
                onServiceToggle={toggleService}
                isBookingSystemEnabled={isBookingSystemEnabled}
                isSuperAdmin={isSuperAdmin}
              />
            </div>

            {selectedServices.length > 0 && (
              <BookingSummary
                selectedServices={selectedServices}
                totalDuration={getTotalDuration()}
                totalPrice={getTotalPrice()}
                onContinue={handleContinue}
                isBookingSystemEnabled={isBookingSystemEnabled}
                isSuperAdmin={isSuperAdmin}
              />
            )}
          </>
        )}

        {step === 2 && (
          <div className="max-w-3xl mx-auto px-4 sm:px-6">
            <div className="flex items-center mb-6 sm:mb-8">
              <button
                onClick={handleBack}
                className="text-gray-600 hover:text-gray-800 flex items-center p-2 -ml-2 touch-manipulation"
              >
                <svg className="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
                Back
              </button>
            </div>
            <DateTimeSelection
              selectedServices={selectedServices}
              onTimeSlotSelect={handleTimeSlotSelect}
            />
          </div>
        )}

        {step === 3 && user && isTrustedUser && (
          <ConfirmationStep
            selectedServices={selectedServices}
            selectedDateTime={selectedDateTime}
            selectedLocation={selectedLocation}
            totalPrice={getTotalPrice()}
            isSubmitting={isSubmitting}
            error={error}
            onBack={handleBack}
            onConfirm={handleTrustedUserBooking}
          />
        )}

        {step === 4 && user && !isTrustedUser && (
          <PaymentStep
            totalPrice={getTotalPrice()}
            selectedServices={selectedServices}
            selectedDateTime={selectedDateTime}
            selectedLocation={selectedLocation}
            user={user}
            getTotalDuration={getTotalDuration}
            isSubmitting={isSubmitting}
            error={error}
            onBack={handleBack}
          />
        )}
      </div>
    </div>
  );
}

export default Booking;
