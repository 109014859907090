import React from 'react';
import { Sentry } from "react-activity";
import "react-activity/dist/library.css";

const LoadingOverlay = () => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-primary/60 backdrop-blur-sm touch-none">
      <div className="flex flex-col items-center space-y-4 p-6 rounded-lg bg-white/10 backdrop-blur-md">
        <Sentry 
          color="#fff" 
          size={32} 
          speed={1} 
          animating={true} 
        />
        <p className="text-white text-sm sm:text-base font-medium animate-pulse">
          Loading...
        </p>
      </div>
    </div>
  );
};

export default LoadingOverlay;
