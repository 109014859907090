import React from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from '../StrictModeDroppable';

const ServiceList = ({ 
  services, 
  categories, 
  selectedCategory, 
  searchQuery, 
  onCategorySelect, 
  onSearchChange, 
  onEdit, 
  onDelete, 
  isDeleting,
  onDragEnd
}) => {
  const filteredServices = services
    .filter(service => 
      (!selectedCategory || service.category === selectedCategory) &&
      (!searchQuery || 
        service.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        service.description.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );

  const getVariationDuration = (variations) => {
    if (!variations || variations.length === 0) return null;
    
    const validVariations = variations.filter(v => v.bookingLength && typeof v.bookingLength.hours === 'number' && typeof v.bookingLength.minutes === 'number');
    if (validVariations.length === 0) return null;

    const minHours = Math.min(...validVariations.map(v => v.bookingLength.hours));
    const minMinutes = Math.min(...validVariations.map(v => v.bookingLength.minutes));
    const maxHours = Math.max(...validVariations.map(v => v.bookingLength.hours));
    const maxMinutes = Math.max(...validVariations.map(v => v.bookingLength.minutes));

    return `${minHours}h ${minMinutes}m - ${maxHours}h ${maxMinutes}m`;
  };

  return (
    <div className="mt-8">
      <h3 className="text-lg font-medium text-gray-900 mb-4">Existing Services</h3>
      
      <div className="mb-4 space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
        <div className="flex-1">
          <label htmlFor="search" className="block text-sm font-medium text-gray-700">
            Search Services
          </label>
          <input
            type="text"
            id="search"
            value={searchQuery}
            onChange={(e) => onSearchChange(e.target.value)}
            placeholder="Search by title or description"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
          />
        </div>
        <div className="flex-1">
          <label htmlFor="categoryFilter" className="block text-sm font-medium text-gray-700">
            Filter by Category
          </label>
          <select
            id="categoryFilter"
            value={selectedCategory}
            onChange={(e) => onCategorySelect(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
          >
            <option value="">All Categories</option>
            {categories.map((category) => (
              <option key={category.id} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="services">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="mt-4 space-y-4"
            >
              {filteredServices.map((service, index) => (
                <Draggable
                  key={service.id}
                  draggableId={service.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={`bg-gray-50 p-4 rounded-md ${snapshot.isDragging ? 'shadow-lg ring-2 ring-primary' : ''}`}
                    >
                      <div className="flex justify-between items-start">
                        <div className="flex-1">
                          <div className="flex items-center">
                            <div
                              {...provided.dragHandleProps}
                              className="mr-3 text-gray-400 hover:text-gray-600 cursor-move"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8h16M4 16h16" />
                              </svg>
                            </div>
                            <div>
                              <h4 className="text-sm font-medium text-gray-900">{service.title}</h4>
                              <p className="mt-1 text-sm text-gray-500">{service.description}</p>
                              <div className="mt-2 text-sm text-gray-500">
                                <span className="font-medium">Category:</span> {service.category} |{' '}
                                {service.hasVariations ? (
                                  <span>
                                    <span className="font-medium">Variations:</span> {service.variations?.length || 0}
                                  </span>
                                ) : (
                                  <span>
                                    <span className="font-medium">Price:</span> £{service.price}
                                  </span>
                                )} |{' '}
                                <span className="font-medium">Duration:</span>{' '}
                                {service.hasVariations ? (
                                  getVariationDuration(service.variations) || 'N/A'
                                ) : (
                                  service.bookingLength ? 
                                  `${service.bookingLength.hours || 0}h ${service.bookingLength.minutes || 0}m` :
                                  'N/A'
                                )} |{' '}
                                <span className="font-medium">Payment:</span>{' '}
                                {service.requireFullPayment ? 'Full payment required' : 
                                service.depositPercentage ? `${service.depositPercentage}% deposit` : 'Default deposit rate'}
                                {service.hidden && (
                                  <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                    Hidden
                                  </span>
                                )}
                              </div>
                              {service.hasVariations && service.variations?.length > 0 && (
                                <div className="mt-2 space-y-1">
                                  {service.variations.map((variation, index) => (
                                    <div key={index} className="text-sm text-gray-500">
                                      • {variation.name}: £{variation.price}
                                      {variation.bookingLength && ` (${variation.bookingLength.hours || 0}h ${variation.bookingLength.minutes || 0}m)`}
                                      {variation.description && ` - ${variation.description}`}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="flex space-x-2 ml-4">
                          <button
                            onClick={() => onEdit(service)}
                            className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-primary hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => onDelete(service.id)}
                            disabled={isDeleting}
                            className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 disabled:cursor-not-allowed"
                          >
                            {isDeleting ? 'Deleting...' : 'Delete'}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>

      {filteredServices.length === 0 && (
        <div className="text-center text-gray-500">No services found</div>
      )}
    </div>
  );
};

export default ServiceList;
