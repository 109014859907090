import React from 'react';
import dayjs from 'dayjs';

const ListView = ({ bookings, onBookingClick }) => {
  // Mobile card view component
  const MobileBookingCard = ({ booking }) => (
    <div 
      className="bg-white p-4 rounded-lg shadow mb-4 cursor-pointer hover:bg-gray-50"
      onClick={() => onBookingClick(booking)}
    >
      <div className="flex justify-between items-start mb-3">
        <div className="text-sm font-medium">
          {dayjs(booking.dateTime).format('MMM D, YYYY')}
          <div className="text-xs text-gray-500">
            {dayjs(booking.dateTime).format('HH:mm')}
          </div>
        </div>
        <span className={`px-2 py-1 text-xs leading-4 font-semibold rounded-full ${
          booking.status === 'confirmed' ? 'bg-green-100 text-green-800' : 
          booking.status === 'pending' ? 'bg-yellow-100 text-yellow-800' : 
          booking.status === 'cancelled' ? 'bg-gray-100 text-gray-800' :
          booking.status === 'rejected' ? 'bg-red-100 text-red-800' :
          'bg-gray-100 text-gray-800'
        }`}>
          {booking.status.charAt(0).toUpperCase() + booking.status.slice(1)}
        </span>
      </div>

      <div className="space-y-2">
        <div>
          <div className="text-xs text-gray-500">Location</div>
          <div className="text-sm">{booking.locationName}</div>
        </div>

        <div>
          <div className="text-xs text-gray-500">Customer</div>
          <div className="text-sm">
            {booking.userEmail || 'No email available'}
            {!booking.userId && (
              <span className="ml-2 text-xs text-yellow-600 font-medium">
                Imported
              </span>
            )}
          </div>
        </div>

        <div>
          <div className="text-xs text-gray-500">Services</div>
          <div className="text-sm">
            {booking.services.map((service, index) => (
              <div key={index} className="flex items-center space-x-1">
                <span>{service.title}</span>
                {service.selectedVariation && (
                  <span className="text-gray-500 text-xs">
                    ({service.selectedVariation.name})
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>

        <div>
          <div className="text-xs text-gray-500">Total</div>
          <div className="text-sm">
            £{booking.totalPrice}
            {booking.payment && (
              <span className={`ml-2 text-xs ${
                booking.payment.status === 'completed' ? 'text-green-600' : 'text-yellow-600'
              }`}>
                {booking.payment.status === 'completed' ? 'Paid' : 'Payment Pending'}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  // Desktop table view
  const DesktopTable = () => (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Date/Time
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Location
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Customer
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Services
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Total
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Status
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {bookings.map((booking) => (
          <tr key={booking.id} className="hover:bg-gray-50 cursor-pointer"
              onClick={() => onBookingClick(booking)}>
            <td className="px-6 py-4 whitespace-nowrap">
              {dayjs(booking.dateTime).format('MMM D, YYYY HH:mm')}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              {booking.locationName}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="flex flex-col">
                <span>{booking.userEmail || 'No email available'}</span>
                {!booking.userId && (
                  <span className="text-xs text-yellow-600 font-medium">
                    Imported Booking
                  </span>
                )}
              </div>
            </td>
            <td className="px-6 py-4">
              <div className="text-sm">
                {booking.services.map((service, index) => (
                  <div key={index} className="flex items-center space-x-1">
                    <span>{service.title}</span>
                    {service.selectedVariation && (
                      <span className="text-gray-500 text-xs">
                        ({service.selectedVariation.name})
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="flex flex-col">
                <span>£{booking.totalPrice}</span>
                {booking.payment && (
                  <span className={`text-xs ${
                    booking.payment.status === 'completed' ? 'text-green-600' : 'text-yellow-600'
                  }`}>
                    {booking.payment.status === 'completed' ? 'Paid' : 'Payment Pending'}
                  </span>
                )}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                booking.status === 'confirmed' ? 'bg-green-100 text-green-800' : 
                booking.status === 'pending' ? 'bg-yellow-100 text-yellow-800' : 
                booking.status === 'cancelled' ? 'bg-gray-100 text-gray-800' :
                booking.status === 'rejected' ? 'bg-red-100 text-red-800' :
                'bg-gray-100 text-gray-800'
              }`}>
                {booking.status.charAt(0).toUpperCase() + booking.status.slice(1)}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className="bg-gray-100 sm:bg-white sm:shadow sm:overflow-hidden sm:rounded-lg">
      {/* Mobile View */}
      <div className="sm:hidden px-4 py-4 space-y-4">
        {bookings.map((booking) => (
          <MobileBookingCard key={booking.id} booking={booking} />
        ))}
      </div>

      {/* Desktop View */}
      <div className="hidden sm:block">
        <DesktopTable />
      </div>
    </div>
  );
};

export default ListView;
