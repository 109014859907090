import React, { useState, useEffect } from 'react';
import { db } from '../../config/firebase';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc, writeBatch } from 'firebase/firestore';
import ServiceForm from './ServiceForm/ServiceForm';
import ServiceList from './ServiceForm/ServiceList';

const ManageServices = ({ onSuccess }) => {
  const [serviceForm, setServiceForm] = useState({
    title: '',
    category: '',
    categoryId: '',
    price: '',
    description: '',
    bookingLengthHours: '0',
    bookingLengthMinutes: '0',
    hidden: false,
    depositPercentage: '',
    requireFullPayment: false,
    hasVariations: false,
    variations: [],
    order: 0
  });

  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [message, setMessage] = useState({ text: '', type: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [editingService, setEditingService] = useState(null);
  const [variationForm, setVariationForm] = useState({
    name: '',
    price: '',
    description: '',
    bookingLengthHours: '0',
    bookingLengthMinutes: '0'
  });
  const [showVariationForm, setShowVariationForm] = useState(false);

  useEffect(() => {
    fetchCategories();
    fetchServices();
  }, []);

  const fetchCategories = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'categories'));
      const categoriesList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCategories(categoriesList);
    } catch (error) {
      setMessage({ text: 'Error fetching categories: ' + error.message, type: 'error' });
    }
  };

  const fetchServices = async () => {
    setIsLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, 'services'));
      const servicesList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      // Sort by order field, fallback to createdAt for legacy documents
      servicesList.sort((a, b) => {
        if (a.order !== undefined && b.order !== undefined) {
          return a.order - b.order;
        }
        return (a.createdAt?.seconds || 0) - (b.createdAt?.seconds || 0);
      });
      setServices(servicesList);
    } catch (error) {
      setMessage({ text: 'Error fetching services: ' + error.message, type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === 'category') {
      const selectedCategory = categories.find(cat => cat.name === value);
      setServiceForm(prev => ({
        ...prev,
        category: value,
        categoryId: selectedCategory ? selectedCategory.id : ''
      }));
    } else if (name === 'requireFullPayment') {
      setServiceForm(prev => ({
        ...prev,
        requireFullPayment: checked,
        depositPercentage: checked ? '100' : prev.depositPercentage
      }));
    } else if (name === 'hasVariations') {
      setServiceForm(prev => ({
        ...prev,
        hasVariations: checked,
        variations: checked ? prev.variations : []
      }));
    } else {
      setServiceForm(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const handleVariationInputChange = (e) => {
    const { name, value } = e.target;
    setVariationForm(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAddVariation = () => {
    if (!variationForm.name || !variationForm.price) return;
    
    setServiceForm(prev => ({
      ...prev,
      variations: [...prev.variations, {
        ...variationForm,
        price: parseFloat(variationForm.price),
        bookingLength: {
          hours: parseInt(variationForm.bookingLengthHours) || 0,
          minutes: parseInt(variationForm.bookingLengthMinutes) || 0
        }
      }]
    }));
    
    setVariationForm({
      name: '',
      price: '',
      description: '',
      bookingLengthHours: '0',
      bookingLengthMinutes: '0'
    });
    setShowVariationForm(false);
  };

  const handleRemoveVariation = (index) => {
    setServiceForm(prev => ({
      ...prev,
      variations: prev.variations.filter((_, i) => i !== index)
    }));
  };

  const handleDeleteService = async (serviceId) => {
    if (window.confirm('Are you sure you want to delete this service?')) {
      setIsDeleting(true);
      try {
        await deleteDoc(doc(db, 'services', serviceId));
        setMessage({ text: 'Service deleted successfully!', type: 'success' });
        fetchServices();
      } catch (error) {
        setMessage({ text: 'Error deleting service: ' + error.message, type: 'error' });
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const handleEditService = (service) => {
    setEditingService(service.id);
    setServiceForm({
      title: service.title,
      category: service.category,
      categoryId: service.categoryId,
      price: service.price.toString(),
      description: service.description,
      bookingLengthHours: service.bookingLength.hours.toString(),
      bookingLengthMinutes: service.bookingLength.minutes.toString(),
      hidden: service.hidden || false,
      depositPercentage: service.depositPercentage?.toString() || '',
      requireFullPayment: service.requireFullPayment || false,
      hasVariations: service.hasVariations || false,
      variations: service.variations || [],
      order: service.order || 0
    });
  };

  const resetForm = () => {
    setServiceForm({
      title: '',
      category: '',
      categoryId: '',
      price: '',
      description: '',
      bookingLengthHours: '0',
      bookingLengthMinutes: '0',
      hidden: false,
      depositPercentage: '',
      requireFullPayment: false,
      hasVariations: false,
      variations: [],
      order: services.length
    });
    setEditingService(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const priceNumber = parseFloat(serviceForm.price.replace('£', ''));
      const bookingLengthHours = parseInt(serviceForm.bookingLengthHours) || 0;
      const bookingLengthMinutes = parseInt(serviceForm.bookingLengthMinutes) || 0;
      const depositPercentage = serviceForm.requireFullPayment ? 100 : (parseInt(serviceForm.depositPercentage) || null);
      
      const serviceData = {
        title: serviceForm.title,
        category: serviceForm.category,
        categoryId: serviceForm.categoryId,
        price: priceNumber,
        description: serviceForm.description,
        bookingLength: {
          hours: bookingLengthHours,
          minutes: bookingLengthMinutes
        },
        hidden: serviceForm.hidden,
        depositPercentage: depositPercentage,
        requireFullPayment: serviceForm.requireFullPayment,
        hasVariations: serviceForm.hasVariations,
        variations: serviceForm.variations.map(variation => ({
          ...variation,
          bookingLength: {
            hours: parseInt(variation.bookingLength.hours) || 0,
            minutes: parseInt(variation.bookingLength.minutes) || 0
          }
        })),
        updatedAt: new Date(),
        order: serviceForm.order
      };

      if (editingService) {
        await updateDoc(doc(db, 'services', editingService), serviceData);
        setMessage({ text: 'Service updated successfully!', type: 'success' });
      } else {
        serviceData.createdAt = new Date();
        await addDoc(collection(db, 'services'), serviceData);
        setMessage({ text: 'Service added successfully!', type: 'success' });
      }

      resetForm();
      fetchServices();
      if (onSuccess) onSuccess();
    } catch (error) {
      setMessage({ text: `Error ${editingService ? 'updating' : 'adding'} service: ${error.message}`, type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const allServices = Array.from(services);
    const categoryServices = selectedCategory 
      ? allServices.filter(service => service.category === selectedCategory)
      : allServices;

    const [reorderedItem] = categoryServices.splice(result.source.index, 1);
    categoryServices.splice(result.destination.index, 0, reorderedItem);

    // If filtering by category, only update orders within that category
    if (selectedCategory) {
      // Get all services not in the selected category
      const otherServices = allServices.filter(service => service.category !== selectedCategory);
      
      // Combine the reordered category services with other services
      const updatedServices = [
        ...otherServices,
        ...categoryServices
      ];

      // Sort all services to maintain overall order
      updatedServices.sort((a, b) => {
        if (a.category === selectedCategory && b.category === selectedCategory) {
          return categoryServices.indexOf(a) - categoryServices.indexOf(b);
        }
        return a.order - b.order;
      });

      // Update order field for all services
      const batch = writeBatch(db);
      updatedServices.forEach((service, index) => {
        const serviceRef = doc(db, 'services', service.id);
        batch.update(serviceRef, { order: index });
      });

      try {
        await batch.commit();
        setServices(updatedServices);
        setMessage({ text: 'Services reordered successfully!', type: 'success' });
      } catch (error) {
        setMessage({ text: 'Error updating service order: ' + error.message, type: 'error' });
        fetchServices(); // Refresh the list to original state
      }
    } else {
      // If not filtering, update all services order
      const batch = writeBatch(db);
      categoryServices.forEach((service, index) => {
        const serviceRef = doc(db, 'services', service.id);
        batch.update(serviceRef, { order: index });
      });

      try {
        await batch.commit();
        setServices(categoryServices);
        setMessage({ text: 'Services reordered successfully!', type: 'success' });
      } catch (error) {
        setMessage({ text: 'Error updating service order: ' + error.message, type: 'error' });
        fetchServices(); // Refresh the list to original state
      }
    }
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2 className="text-lg leading-6 font-medium text-gray-900">
          Manage Services
        </h2>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Add, edit, and manage service offerings. Drag and drop to reorder.
        </p>
      </div>

      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        {message.text && (
          <div className={`mb-4 p-4 rounded ${message.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
            {message.text}
          </div>
        )}

        <ServiceForm
          serviceForm={serviceForm}
          categories={categories}
          onInputChange={handleInputChange}
          onSubmit={handleSubmit}
          isLoading={isLoading}
          editingService={editingService}
          onCancel={resetForm}
          variationForm={variationForm}
          showVariationForm={showVariationForm}
          onVariationInputChange={handleVariationInputChange}
          onAddVariation={handleAddVariation}
          onCancelVariation={() => setShowVariationForm(false)}
          onRemoveVariation={handleRemoveVariation}
          setShowVariationForm={setShowVariationForm}
        />

        <ServiceList
          services={services}
          categories={categories}
          selectedCategory={selectedCategory}
          searchQuery={searchQuery}
          onCategorySelect={setSelectedCategory}
          onSearchChange={setSearchQuery}
          onEdit={handleEditService}
          onDelete={handleDeleteService}
          isDeleting={isDeleting}
          onDragEnd={handleDragEnd}
        />
      </div>
    </div>
  );
};

export default ManageServices;
