import React, { useState, useEffect } from 'react';
import Logo from '../../assets/images/Logo.png';
import { Link } from 'react-router-dom';

// Import all images from the HomeHeroImages directory
import SCHero1 from '../../assets/images/HomeHeroImages/SCHero1.jpeg';
import SCHero2 from '../../assets/images/HomeHeroImages/SCHero2.jpeg';
import SCHero3 from '../../assets/images/HomeHeroImages/SCHero3.jpeg';

const images = [SCHero1, SCHero2, SCHero3];

function HomeHero() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 8000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative h-[100svh] w-full overflow-hidden">
      {images.map((image, index) => (
        <div
          key={index}
          className={`absolute inset-0 w-full h-full transition-opacity duration-2000
            ${index === currentImageIndex ? 'opacity-100' : 'opacity-0'}`}
        >
          <div
            className="absolute inset-0 w-full h-full bg-cover bg-center animate-kenburns"
            style={{
              backgroundImage: `url(${image})`,
              animationDelay: `${index * -8}s`,
            }}
          />
        </div>
      ))}
      
      <div className="relative z-10 flex items-center justify-center h-full">
        <div className="absolute inset-0 bg-gradient-to-b from-primary/95 via-primary/85 to-primary/75"></div>
        <div className="hero-content text-neutral-content text-center relative z-20 px-4 sm:px-6">
          <div className=" mx-auto">
            <img 
              src={Logo} 
              alt="Logo" 
              className="w-32 h-32 sm:w-40 sm:h-40 mx-auto mb-4 sm:mb-5" 
            />
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-secondary text-white tracking-widest-plus mb-2">
              THE SKIN
            </h1>
            <h2 className="font-primary text-3xl sm:text-4xl md:text-5xl text-white tracking-super-wide">
              CHALET
            </h2>
           
            <Link 
              to="/booking"
              className="inline-block mt-12 sm:mt-16 w-full sm:w-auto"
            >
              <button className="w-full sm:w-auto px-8 py-4 sm:py-3 bg-white text-primary hover:bg-white/90 active:bg-white/80 transition-colors duration-200 rounded-lg text-base sm:text-lg font-medium tracking-wide touch-manipulation">
                Book an Appointment
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeHero;
