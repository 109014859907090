import React, { useState, useEffect } from 'react';
import { db } from '../../../config/firebase';
import { collection, getDocs, addDoc, Timestamp, doc, getDoc } from 'firebase/firestore';
import { useBooking } from '../../../context/BookingContext';
import VariationModal from '../../Common/VariationModal';

const ManualBooking = ({ onClose }) => {
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedService, setSelectedService] = useState('');
  const [selectedDateTime, setSelectedDateTime] = useState('');
  const [clients, setClients] = useState([]);
  const [clientSearchTerm, setClientSearchTerm] = useState('');
  const [serviceSearchTerm, setServiceSearchTerm] = useState('');
  const [showClientDropdown, setShowClientDropdown] = useState(false);
  const [showServiceDropdown, setShowServiceDropdown] = useState(false);
  const [loading, setLoading] = useState(true);
  const [defaultDepositPercentage, setDefaultDepositPercentage] = useState(50);
  const [showVariationModal, setShowVariationModal] = useState(false);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const { allServices = [], locations = [], selectedLocation, setSelectedLocation } = useBooking();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch clients
        const clientsSnapshot = await getDocs(collection(db, 'users'));
        const clientsData = clientsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setClients(clientsData);

        // Fetch default deposit percentage from booking settings
        const settingsDoc = await getDoc(doc(db, 'settings', 'booking'));
        if (settingsDoc.exists()) {
          const data = settingsDoc.data();
          if (data.depositPercentage !== undefined) {
            setDefaultDepositPercentage(data.depositPercentage);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const filteredClients = clients.filter(client => {
    if (!client.firstName || !client.lastName) return false;
    const fullName = `${client.firstName} ${client.lastName}`.toLowerCase();
    return fullName.includes(clientSearchTerm.toLowerCase());
  });

  const filteredServices = allServices.filter(service => {
    if (!service || !service.title) return false;
    return service.title.toLowerCase().includes(serviceSearchTerm.toLowerCase());
  });

  const handleClientSelect = (client) => {
    setSelectedClient(client.id);
    setClientSearchTerm(`${client.firstName} ${client.lastName}`);
    setShowClientDropdown(false);
  };

  const handleServiceSelect = (service) => {
    if (service.hasVariations) {
      setSelectedService(service.id);
      setServiceSearchTerm(service.title);
      setShowServiceDropdown(false);
      setShowVariationModal(true);
      setSelectedVariation(null);
    } else {
      setSelectedService(service.id);
      setServiceSearchTerm(service.title);
      setShowServiceDropdown(false);
      setSelectedVariation(null);
    }
  };

  const handleVariationSelect = (variation) => {
    setSelectedVariation(variation);
    setShowVariationModal(false);
  };

  const calculateDepositAmount = (service, variation) => {
    const price = variation ? variation.price : service.price;
    let depositPercentage;

    if (service.requireFullPayment) {
      depositPercentage = 100;
    } else if (service.depositPercentage) {
      depositPercentage = service.depositPercentage;
    } else {
      depositPercentage = defaultDepositPercentage;
    }

    return Math.floor(price * depositPercentage) / 100;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!selectedClient || !selectedService || !selectedDateTime || !selectedLocation) {
      alert('Please fill in all fields');
      return;
    }

    try {
      const service = allServices.find(s => s.id === selectedService);
      const client = clients.find(c => c.id === selectedClient);
      
      if (!service || !client) {
        throw new Error('Service or client not found');
      }

      if (service.hasVariations && !selectedVariation) {
        alert('Please select a variation for this service');
        return;
      }

      const now = Timestamp.now();
      const bookingDateTime = Timestamp.fromDate(new Date(selectedDateTime));
      const price = selectedVariation ? selectedVariation.price : service.price;
      const depositAmount = calculateDepositAmount(service, selectedVariation);
      const depositPercentage = service.requireFullPayment ? 100 : (service.depositPercentage || defaultDepositPercentage);

      const bookingData = {
        createdAt: now,
        dateTime: bookingDateTime,
        depositAmount,
        isTrustedUser: false,
        locationId: selectedLocation.id,
        locationName: selectedLocation.name,
        payment: {
          amount: depositAmount,
          status: 'awaiting_confirmation'
        },
        services: [{
          bookingLength: service.bookingLength,
          id: service.id,
          price: price,
          title: service.title,
          depositPercentage: depositPercentage,
          requireFullPayment: service.requireFullPayment || false,
          hasVariations: service.hasVariations || false,
          selectedVariation: selectedVariation ? {
            name: selectedVariation.name,
            price: selectedVariation.price,
            description: selectedVariation.description
          } : null
        }],
        status: 'confirmed',
        totalDuration: service.bookingLength,
        totalPrice: price,
        updatedAt: now,
        userEmail: client.email,
        userId: client.id,
        userName: `${client.firstName} ${client.lastName}`
      };

      await addDoc(collection(db, 'bookings'), bookingData);
      onClose();
    } catch (error) {
      console.error('Error creating booking:', error);
      alert('Failed to create booking. Please try again.');
    }
  };

  if (loading) {
    return <div className="p-4">Loading...</div>;
  }

  const selectedServiceData = selectedService ? allServices.find(s => s.id === selectedService) : null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Manual Booking</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            ×
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="relative">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Client Search
            </label>
            <input
              type="text"
              value={clientSearchTerm}
              onChange={(e) => {
                setClientSearchTerm(e.target.value);
                setShowClientDropdown(true);
              }}
              onFocus={() => setShowClientDropdown(true)}
              placeholder="Search by name"
              className="w-full border rounded-md p-2 mb-2"
            />
            {showClientDropdown && clientSearchTerm && (
              <div className="absolute z-10 w-full max-h-40 overflow-y-auto border rounded-md bg-white shadow-lg">
                {filteredClients.map(client => (
                  <div
                    key={client.id}
                    onClick={() => handleClientSelect(client)}
                    className="p-2 hover:bg-gray-100 cursor-pointer"
                  >
                    {client.firstName} {client.lastName}
                    <div className="text-sm text-gray-500">{client.email}</div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="relative">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Service Search
            </label>
            <input
              type="text"
              value={serviceSearchTerm}
              onChange={(e) => {
                setServiceSearchTerm(e.target.value);
                setShowServiceDropdown(true);
              }}
              onFocus={() => setShowServiceDropdown(true)}
              placeholder="Search for a service"
              className="w-full border rounded-md p-2 mb-2"
            />
            {showServiceDropdown && serviceSearchTerm && (
              <div className="absolute z-10 w-full max-h-40 overflow-y-auto border rounded-md bg-white shadow-lg">
                {filteredServices.map(service => (
                  <div
                    key={service.id}
                    onClick={() => handleServiceSelect(service)}
                    className="p-2 hover:bg-gray-100 cursor-pointer"
                  >
                    <div>
                      {service.title}
                      {service.hasVariations ? (
                        <span className="text-sm text-gray-500 ml-1">
                          (from £{Math.min(...service.variations.map(v => v.price))})
                        </span>
                      ) : (
                        <span className="ml-1">- £{service.price}</span>
                      )}
                    </div>
                    <div className="text-sm text-gray-500">
                      {service.requireFullPayment ? 'Full payment required' :
                       service.depositPercentage ? `${service.depositPercentage}% deposit required` :
                       `${defaultDepositPercentage}% deposit required`}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          {selectedServiceData && (
            <div className="bg-gray-50 p-3 rounded-md">
              <h3 className="text-sm font-medium text-gray-700 mb-2">Service Details</h3>
              <div className="text-sm text-gray-600">
                <p>
                  {selectedServiceData.title}
                  {selectedVariation && (
                    <span className="text-gray-500 ml-1">
                      ({selectedVariation.name})
                    </span>
                  )}
                </p>
                <p>
                  Price: £{selectedVariation ? selectedVariation.price : selectedServiceData.price}
                </p>
                <p>
                  {selectedServiceData.requireFullPayment ? 'Full payment required' :
                   `Deposit Required: £${calculateDepositAmount(selectedServiceData, selectedVariation)} 
                   (${selectedServiceData.depositPercentage || defaultDepositPercentage}%)`}
                </p>
                {selectedServiceData.hasVariations && !selectedVariation && (
                  <button
                    type="button"
                    onClick={() => setShowVariationModal(true)}
                    className="mt-2 text-primary hover:text-primary-dark"
                  >
                    Select Variation
                  </button>
                )}
              </div>
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Location
            </label>
            <select
              value={selectedLocation?.id || ''}
              onChange={(e) => {
                const location = locations.find(loc => loc.id === e.target.value);
                setSelectedLocation(location);
              }}
              className="w-full border rounded-md p-2"
              required
            >
              <option value="">Select a location</option>
              {locations.map(location => (
                <option key={location.id} value={location.id}>
                  {location.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Date & Time
            </label>
            <input
              type="datetime-local"
              value={selectedDateTime}
              onChange={(e) => setSelectedDateTime(e.target.value)}
              className="w-full border rounded-md p-2"
              required
            />
          </div>

          <div className="flex justify-end space-x-2 mt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:text-gray-800"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary/90"
            >
              Create Booking
            </button>
          </div>
        </form>

        {showVariationModal && selectedServiceData && (
          <VariationModal
            isOpen={showVariationModal}
            onClose={() => setShowVariationModal(false)}
            variations={selectedServiceData.variations || []}
            onSelect={handleVariationSelect}
            title={`Select ${selectedServiceData.title} Variation`}
          />
        )}
      </div>
    </div>
  );
};

export default ManualBooking;
