import React, { createContext, useContext, useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useBookingSystem } from './BookingSystemContext';
import { useAuth } from './AuthContext';

const BookingContext = createContext();

export function BookingProvider({ children }) {
  const [categories, setCategories] = useState([]);
  const [allServices, setAllServices] = useState([]); // Store all services
  const [locations, setLocations] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { isBookingSystemEnabled } = useBookingSystem();
  const { isSuperAdmin } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Query with orderBy for categories and services
        const categoriesQuery = query(collection(db, 'categories'), orderBy('order', 'asc'));
        const servicesQuery = query(collection(db, 'services'), orderBy('order', 'asc'));
        const locationsQuery = query(collection(db, 'locations'));

        const [categoriesSnapshot, servicesSnapshot, locationsSnapshot] = await Promise.all([
          getDocs(categoriesQuery),
          getDocs(servicesQuery),
          getDocs(locationsQuery)
        ]);

        const categoriesData = categoriesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCategories(categoriesData);
        
        // Set the first category as the selected category if categories exist
        if (categoriesData.length > 0) {
          setSelectedCategory(categoriesData[0].name.toLowerCase());
        }

        const servicesData = servicesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setAllServices(servicesData); // Store all services

        const locationsData = locationsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setLocations(locationsData);

        // Auto-select location if there's only one
        if (locationsData.length === 1) {
          setSelectedLocation(locationsData[0]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load services. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Clear selected services when booking system is disabled
  useEffect(() => {
    if (!isBookingSystemEnabled && !isSuperAdmin) {
      setSelectedServices([]);
    }
  }, [isBookingSystemEnabled, isSuperAdmin]);

  const toggleService = (service) => {
    // Prevent service selection if booking is disabled
    if (!isBookingSystemEnabled && !isSuperAdmin) {
      return;
    }

    setSelectedServices(prev => {
      const isSelected = prev.find(s => s.id === service.id);
      if (isSelected) {
        return prev.filter(s => s.id !== service.id);
      } else {
        return [...prev, service];
      }
    });
  };

  const resetBooking = () => {
    setSelectedServices([]);
    // When resetting, set to first category instead of null
    setSelectedCategory(categories.length > 0 ? categories[0].name.toLowerCase() : null);
  };

  const getTotalDuration = () => {
    const totalMinutes = selectedServices.reduce((total, service) => {
      const serviceHours = service.bookingLength?.hours || 0;
      const serviceMinutes = service.bookingLength?.minutes || 0;
      return total + (serviceHours * 60) + serviceMinutes;
    }, 0);

    return {
      hours: Math.floor(totalMinutes / 60),
      minutes: totalMinutes % 60
    };
  };

  const getTotalPrice = () => {
    return selectedServices.reduce((total, service) => total + (service.price || 0), 0);
  };

  const getOrderedServices = () => {
    // Filter out hidden services
    const visibleServices = allServices.filter(service => !service.hidden);
    
    if (selectedCategory) {
      // When a category is selected, return services for that category in their order
      return visibleServices.filter(service => 
        service.category.toLowerCase() === selectedCategory.toLowerCase()
      );
    }
    
    // When no category is selected, maintain the order from the database
    // Services are already ordered by the 'order' field from the initial query
    return visibleServices;
  };

  const filteredServices = getOrderedServices();

  return (
    <BookingContext.Provider
      value={{
        categories,
        services: filteredServices,
        allServices, // Provide all services
        locations,
        selectedServices,
        selectedCategory,
        selectedLocation,
        loading,
        error,
        setSelectedCategory,
        setSelectedLocation,
        toggleService,
        getTotalDuration,
        getTotalPrice,
        resetBooking,
        isBookingSystemEnabled,
        isSuperAdmin
      }}
    >
      {children}
    </BookingContext.Provider>
  );
}

export function useBooking() {
  const context = useContext(BookingContext);
  if (!context) {
    throw new Error('useBooking must be used within a BookingProvider');
  }
  return context;
}
