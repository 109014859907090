import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../config/firebase';

const DailyView = ({ 
  currentDate, 
  onPreviousDay, 
  onNextDay, 
  onBookingClick, 
  bookings,
  blockedTimes = []
}) => {
  const [businessHours, setBusinessHours] = useState({
    monday: { start: '09:00', end: '17:00', isOpen: true },
    tuesday: { start: '09:00', end: '17:00', isOpen: true },
    wednesday: { start: '09:00', end: '17:00', isOpen: true },
    thursday: { start: '09:00', end: '17:00', isOpen: true },
    friday: { start: '09:00', end: '17:00', isOpen: true },
    saturday: { start: '09:00', end: '17:00', isOpen: false },
    sunday: { start: '09:00', end: '17:00', isOpen: false },
  });
  const [currentTime, setCurrentTime] = useState(dayjs());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(dayjs());
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const fetchBookingSettings = async () => {
      try {
        const settingsDoc = await getDocs(collection(db, 'settings'));
        const bookingSettings = settingsDoc.docs.find(doc => doc.id === 'booking');
        if (bookingSettings) {
          const data = bookingSettings.data();
          setBusinessHours(data.businessHours || businessHours);
        }
      } catch (error) {
        console.error('Error fetching booking settings:', error);
      }
    };

    fetchBookingSettings();
  }, []);

  const getTimeRange = () => {
    let earliestStart = 24;
    let latestEnd = 0;

    Object.values(businessHours).forEach(hours => {
      if (hours.isOpen) {
        const [startHour] = hours.start.split(':').map(Number);
        const [endHour] = hours.end.split(':').map(Number);
        
        earliestStart = Math.min(earliestStart, startHour);
        latestEnd = Math.max(latestEnd, endHour);
      }
    });

    return Array.from(
      { length: latestEnd - earliestStart },
      (_, i) => earliestStart + i
    );
  };

  const timeSlots = getTimeRange();

  const handleBookingClick = (e, booking) => {
    e.stopPropagation();
    if (onBookingClick) {
      onBookingClick(booking);
    }
  };

  const getBookingColor = (status, isImported = false, hasOverlap = false) => {
    const baseColors = {
      confirmed: isImported ? 'bg-green-50 border-green-200 text-green-800' : 'bg-green-100 border-green-200 text-green-800',
      pending: isImported ? 'bg-yellow-50 border-yellow-200 text-yellow-800' : 'bg-yellow-100 border-yellow-200 text-yellow-800',
      rejected: isImported ? 'bg-red-50 border-red-200 text-red-800' : 'bg-red-100 border-red-200 text-red-800',
      cancelled: isImported ? 'bg-gray-50 border-gray-200 text-gray-800' : 'bg-gray-100 border-gray-200 text-gray-800',
      blocked: 'bg-gray-200/70 border-gray-300 text-gray-800'
    };

    const colors = baseColors[status] || baseColors.blocked;
    if (hasOverlap && status === 'blocked') {
      return `${colors} bg-stripes`;
    }
    return colors;
  };

  const calculateBookingPosition = (booking, slotStartHour, index, total) => {
    const bookingTime = dayjs(booking.dateTime);
    const bookingHour = bookingTime.hour();
    const bookingMinute = bookingTime.minute();
    
    const totalDuration = booking.totalDuration || { hours: 0, minutes: 0 };
    const totalDurationMinutes = (totalDuration.hours * 60) + totalDuration.minutes;
    
    const hourHeight = 100;
    const topOffset = ((bookingHour - slotStartHour) * hourHeight) + ((bookingMinute / 60) * hourHeight);
    const height = (totalDurationMinutes / 60) * hourHeight;
    
    const width = total > 1 ? `${95 / total}%` : '95%';
    const left = total > 1 ? `${(index * 95) / total}%` : '2.5%';
    
    return {
      top: `${topOffset}px`,
      height: `${height}px`,
      width,
      left
    };
  };

  const calculateBlockedTimePosition = (block, slotStartHour) => {
    const [hours, minutes] = block.startTime.split(':').map(Number);
    const durationMinutes = parseInt(block.duration);
    
    const hourHeight = 100;
    const topOffset = ((hours - slotStartHour) * hourHeight) + ((minutes / 60) * hourHeight);
    const height = (durationMinutes / 60) * hourHeight;
    
    return {
      top: `${topOffset}px`,
      height: `${height}px`,
      width: '95%',
      left: '2.5%'
    };
  };

  const calculateTimelinePosition = () => {
    const currentHour = currentTime.hour();
    const currentMinute = currentTime.minute();
    const hourHeight = 100;
    const topOffset = ((currentHour - timeSlots[0]) * hourHeight) + ((currentMinute / 60) * hourHeight);
    return {
      top: `${topOffset}px`
    };
  };

  const checkOverlap = (item1, item2) => {
    const start1 = dayjs(typeof item1.dateTime === 'undefined' ? 
      `${item1.date} ${item1.startTime}` : item1.dateTime);
    
    let duration1;
    if (typeof item1.duration === 'undefined') {
      const totalDuration = item1.totalDuration || { hours: 0, minutes: 0 };
      duration1 = (totalDuration.hours * 60) + totalDuration.minutes;
    } else {
      duration1 = parseInt(item1.duration);
    }
    const end1 = start1.add(duration1, 'minute');

    const start2 = dayjs(typeof item2.dateTime === 'undefined' ? 
      `${item2.date} ${item2.startTime}` : item2.dateTime);
    
    let duration2;
    if (typeof item2.duration === 'undefined') {
      const totalDuration = item2.totalDuration || { hours: 0, minutes: 0 };
      duration2 = (totalDuration.hours * 60) + totalDuration.minutes;
    } else {
      duration2 = parseInt(item2.duration);
    }
    const end2 = start2.add(duration2, 'minute');

    return (start1.isBefore(end2) && end1.isAfter(start2)) || start1.isSame(start2);
  };

  const getOverlappingItems = (items, date) => {
    const dayItems = items.filter(item => {
      const itemDate = dayjs(typeof item.dateTime === 'undefined' ? 
        `${item.date}` : item.dateTime).format('YYYY-MM-DD');
      return itemDate === date;
    });

    const groups = [];
    dayItems.forEach(item => {
      let added = false;
      for (let group of groups) {
        if (group.some(groupItem => checkOverlap(groupItem, item))) {
          group.push(item);
          added = true;
          break;
        }
      }
      if (!added) {
        groups.push([item]);
      }
    });

    return groups;
  };

  const formatDuration = (totalDuration) => {
    if (!totalDuration) return '0h 0m';
    return `${totalDuration.hours}h ${totalDuration.minutes}m`;
  };

  const isToday = currentDate.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD');
  const dayDate = currentDate.format('YYYY-MM-DD');
  const dayBlockedTimes = blockedTimes.filter(block => block.date === dayDate);
  const allItems = [
    ...bookings.map(b => ({ ...b, type: 'booking' })),
    ...dayBlockedTimes.map(b => ({ ...b, type: 'blocked' }))
  ];
  const overlappingGroups = getOverlappingItems(allItems, dayDate);

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg p-2 sm:p-4">
      <style>
        {`
          .bg-stripes {
            background-image: repeating-linear-gradient(
              45deg,
              transparent,
              transparent 10px,
              rgba(0,0,0,0.05) 10px,
              rgba(0,0,0,0.05) 20px
            );
          }
          .timeline {
            position: absolute;
            left: 0;
            right: 0;
            height: 2px;
            background-color: #ef4444;
            z-index: 10;
          }
          .timeline::before {
            content: '';
            position: absolute;
            left: -4px;
            top: -4px;
            width: 10px;
            height: 10px;
            background-color: #ef4444;
            border-radius: 50%;
          }
          .timeline-tooltip {
            position: absolute;
            left: 12px;
            top: -25px;
            background-color: #ef4444;
            color: white;
            padding: 2px 8px;
            border-radius: 4px;
            font-size: 12px;
            opacity: 0;
            transition: opacity 0.2s;
            pointer-events: none;
          }
          .timeline:hover .timeline-tooltip {
            opacity: 1;
          }
        `}
      </style>

      <div className="flex items-center justify-between mb-4">
        <button
          onClick={onPreviousDay}
          className="p-3 hover:bg-gray-100 rounded-full touch-manipulation"
          aria-label="Previous day"
        >
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <div className="text-base sm:text-xl font-semibold text-center">
          {currentDate.format('dddd, MMMM D, YYYY')}
        </div>
        <button
          onClick={onNextDay}
          className="p-3 hover:bg-gray-100 rounded-full touch-manipulation"
          aria-label="Next day"
        >
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>

      <div className="grid grid-cols-8 min-w-[800px]">
        {/* Time column */}
        <div className="border-r">
          <div className="h-12"></div>
          {timeSlots.map((hour) => (
            <div key={hour} className="h-[100px] border-t p-1 text-xs text-gray-500">
              {hour.toString().padStart(2, '0')}:00
            </div>
          ))}
        </div>

        {/* Day column */}
        <div className={`border-r relative flex-1 col-span-7 ${isToday ? 'bg-primary/5' : ''}`}>
          <div className={`h-12 border-b p-2 text-center font-medium ${isToday ? 'bg-primary/10 border-primary' : ''}`}>
            <div className="text-base sm:text-sm">{currentDate.format('ddd')}</div>
            <div className="text-sm text-gray-500">{currentDate.format('MMM D')}</div>
          </div>
          <div className="relative">
            {timeSlots.map((hour) => (
              <div
                key={hour}
                className="h-[100px] border-t p-1 text-xs sm:text-sm"
              >
                {hour.toString().padStart(2, '0')}:00
              </div>
            ))}
            {isToday && (
              <div className="timeline" style={calculateTimelinePosition()}>
                <div className="timeline-tooltip">
                  {currentTime.format('h:mm A')}
                </div>
              </div>
            )}
            {overlappingGroups.map((group, groupIndex) => (
              <React.Fragment key={groupIndex}>
                {group
                  .filter(item => item.type === 'blocked')
                  .map((item) => {
                    const position = calculateBlockedTimePosition(item, timeSlots[0]);
                    const hasOverlap = group.length > 1;
                    return (
                      <div
                        key={item.id}
                        className={`absolute p-2 text-sm rounded overflow-hidden border ${getBookingColor('blocked', false, hasOverlap)}`}
                        style={position}
                      >
                        <div className="font-medium truncate">Blocked Time</div>
                        <div className="truncate">{item.startTime} ({parseInt(item.duration) / 60}h)</div>
                        {hasOverlap && (
                          <div className="text-xs text-red-600 font-medium mt-1">
                            Has overlapping bookings
                          </div>
                        )}
                      </div>
                    );
                  })}
                {group
                  .filter(item => item.type === 'booking')
                  .map((item, itemIndex) => {
                    const position = calculateBookingPosition(
                      item, 
                      timeSlots[0], 
                      itemIndex,
                      group.filter(i => i.type === 'booking').length
                    );
                    const isImported = !item.userId;
                    const colorClasses = getBookingColor(item.status, isImported);
                    return (
                      <div
                        key={item.id}
                        className={`absolute p-2 text-sm rounded overflow-hidden cursor-pointer hover:opacity-75 transition-opacity border shadow-sm ${colorClasses}`}
                        style={position}
                        onClick={(e) => handleBookingClick(e, item)}
                      >
                        <div className="font-medium truncate">
                          {item.userName || item.userEmail || 'No contact info'}
                          {isImported && (
                            <span className="ml-1 text-yellow-600">(Imported)</span>
                          )}
                        </div>
                        <div className="truncate">
                          {item.services?.[0]?.title}
                          {item.services?.length > 1 ? ` +${item.services.length - 1}` : ''}
                        </div>
                        <div className="text-sm text-gray-500">
                          {dayjs(item.dateTime).format('h:mm A')} | {formatDuration(item.totalDuration)}
                        </div>
                      </div>
                    );
                  })}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyView;
