import React, { useState, useEffect } from 'react';
import { db } from '../../config/firebase';
import { collection, query, where, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import dayjs from 'dayjs';

const UserDetails = ({ userId, onClose }) => {
  const [appointments, setAppointments] = useState([]);
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        console.log('Fetching user data for userId:', userId);
        
        // Fetch user data
        const userDoc = await getDoc(doc(db, 'users', userId));
        const userData = userDoc.data();
        console.log('User data:', userData);
        setUser(userData);
        setNotes(userData?.adminNotes || '');

        // Fetch appointments
        const appointmentsQuery = query(
          collection(db, 'bookings'),
          where('userId', '==', userId)
        );
        console.log('Fetching appointments with query:', appointmentsQuery);
        
        const appointmentsSnapshot = await getDocs(appointmentsQuery);
        console.log('Appointments snapshot size:', appointmentsSnapshot.size);
        
        const appointmentsList = appointmentsSnapshot.docs.map(doc => {
          const data = doc.data();
          console.log('Appointment data:', data);
          
          // Convert Firestore timestamp to dayjs object
          const dateTime = data.dateTime && typeof data.dateTime.toDate === 'function' 
            ? dayjs(data.dateTime.toDate())
            : null;

          return {
            id: doc.id,
            ...data,
            dateTime
          };
        }).filter(appointment => appointment.dateTime !== null); // Filter out appointments with invalid dates
        
        // Sort appointments by date, most recent first
        const sortedAppointments = appointmentsList.sort((a, b) => b.dateTime.valueOf() - a.dateTime.valueOf());
        console.log('Sorted appointments:', sortedAppointments);
        
        setAppointments(sortedAppointments);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  const saveNotes = async () => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        adminNotes: notes
      });
    } catch (error) {
      console.error('Error saving notes:', error);
    }
  };

  const getStatusStyle = (status) => {
    switch (status?.toLowerCase()) {
      case 'confirmed':
        return 'bg-green-100 border-green-200 text-green-800';
      case 'pending':
        return 'bg-yellow-100 border-yellow-200 text-yellow-800';
      case 'rejected':
        return 'bg-red-100 border-red-200 text-red-800';
      case 'cancelled':
        return 'bg-gray-100 border-gray-200 text-gray-800';
      default:
        return 'bg-yellow-100 border-yellow-200 text-yellow-800'; // Default to pending style
    }
  };

  const getServiceDisplay = (services) => {
    if (!services || services.length === 0) return 'Unknown Service';
    return services[0].title + (services.length > 1 ? ` +${services.length - 1}` : '');
  };

  const getDurationDisplay = (totalDuration) => {
    if (!totalDuration) return '';
    const hours = totalDuration.hours || 0;
    const minutes = totalDuration.minutes || 0;
    return `${hours}h ${minutes}m`;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-1/2 shadow-lg rounded-md bg-white">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">{user?.email}</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="mb-6">
          <h3 className="text-lg font-medium mb-2">Admin Notes</h3>
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className="w-full p-2 border rounded-md"
            rows="4"
          />
          <button
            onClick={saveNotes}
            className="mt-2 bg-primary text-white px-4 py-2 rounded-md hover:bg-opacity-90"
          >
            Save Notes
          </button>
        </div>

        <div>
          <h3 className="text-lg font-medium mb-2">Previous Appointments</h3>
          {appointments.length === 0 ? (
            <p className="text-gray-500">No previous appointments</p>
          ) : (
            <div className="space-y-4">
              {appointments.map((appointment) => (
                <div
                  key={appointment.id}
                  className="border rounded-md p-4"
                >
                  <div className="flex justify-between">
                    <div>
                      <p className="font-medium">{getServiceDisplay(appointment.services)}</p>
                      <p className="text-sm text-gray-500">
                        {appointment.dateTime.format('MMM D, YYYY')} at {appointment.dateTime.format('h:mm A')}
                      </p>
                      <p className="text-xs text-gray-500 mt-1">
                        {getDurationDisplay(appointment.totalDuration)}
                      </p>
                    </div>
                    <div className="text-right">
                      <span className={`text-sm px-2 py-1 rounded-full border ${getStatusStyle(appointment.status)}`}>
                        {appointment.status?.charAt(0).toUpperCase() + appointment.status?.slice(1) || 'Pending'}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
