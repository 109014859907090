import imageCompression from 'browser-image-compression';

/**
 * Compresses an image file using browser-image-compression
 * @param {File} imageFile - The image file to compress
 * @param {Object} options - Optional compression options
 * @returns {Promise<File>} - The compressed image file
 */
export async function compressImage(imageFile, options = {}) {
  const defaultOptions = {
    maxSizeMB: 1,             // Maximum size in MB
    maxWidthOrHeight: 1920,   // Max width/height - good for most displays
    useWebWorker: true,       // Use web worker for better performance
    initialQuality: 0.8,      // Initial quality (0 to 1)
  };

  const compressionOptions = { ...defaultOptions, ...options };

  try {
    // Skip compression for small images (less than maxSizeMB)
    if (imageFile.size / 1024 / 1024 < compressionOptions.maxSizeMB) {
      console.log('Image is already smaller than target size, skipping compression');
      return imageFile;
    }

    console.log('Original image size:', imageFile.size / 1024 / 1024, 'MB');
    const compressedFile = await imageCompression(imageFile, compressionOptions);
    console.log('Compressed image size:', compressedFile.size / 1024 / 1024, 'MB');
    
    return compressedFile;
  } catch (error) {
    console.error('Error compressing image:', error);
    throw error;
  }
}
