import React, { useState, useEffect } from 'react';
import { db } from '../../config/firebase';
import { doc, setDoc, getDoc, collection, query, getDocs, where } from 'firebase/firestore';
import dayjs from 'dayjs';

import BusinessHoursSection from './BookingSettingsSections/BusinessHoursSection';
import HolidayDatesSection from './BookingSettingsSections/HolidayDatesSection';
import BlockedTimesSection from './BookingSettingsSections/BlockedTimesSection';
import DepositSection from './BookingSettingsSections/DepositSection';
import BookingStatusSection from './BookingSettingsSections/BookingStatusSection';

const BookingSettings = () => {
  const [businessHours, setBusinessHours] = useState({
    monday: { start: '09:00', end: '17:00', isOpen: true },
    tuesday: { start: '09:00', end: '17:00', isOpen: true },
    wednesday: { start: '09:00', end: '17:00', isOpen: true },
    thursday: { start: '09:00', end: '17:00', isOpen: true },
    friday: { start: '09:00', end: '17:00', isOpen: true },
    saturday: { start: '09:00', end: '17:00', isOpen: false },
    sunday: { start: '09:00', end: '17:00', isOpen: false },
  });
  const [holidayDates, setHolidayDates] = useState([]);
  const [blockedTimes, setBlockedTimes] = useState([]);
  const [newHolidayDate, setNewHolidayDate] = useState('');
  const [newBlockedTime, setNewBlockedTime] = useState({
    date: '',
    startTime: '',
    duration: '60'
  });
  const [depositPercentage, setDepositPercentage] = useState(0);
  const [autoConfirm, setAutoConfirm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saveStatus, setSaveStatus] = useState('');
  const [existingBookings, setExistingBookings] = useState([]);
  const [conflictError, setConflictError] = useState('');

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settingsDoc = await getDoc(doc(db, 'settings', 'booking'));
        if (settingsDoc.exists()) {
          const data = settingsDoc.data();
          setBusinessHours(data.businessHours || businessHours);
          setHolidayDates(data.holidayDates || []);
          setBlockedTimes(data.blockedTimes || []);
          setDepositPercentage(data.depositPercentage || 0);
          setAutoConfirm(data.autoConfirm || false);
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        const bookingsQuery = query(
          collection(db, 'bookings'),
          where('dateTime', '>=', today)
        );
        
        const snapshot = await getDocs(bookingsQuery);
        const bookingsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          dateTime: doc.data().dateTime?.toDate()
        }));
        
        setExistingBookings(bookingsData);
      } catch (error) {
        console.error('Error fetching bookings:', error);
      }
    };

    fetchBookings();
  }, []);

  const handleHoursChange = (day, field, value) => {
    setBusinessHours(prev => ({
      ...prev,
      [day]: {
        ...prev[day],
        [field]: value
      }
    }));
  };

  const handleDayToggle = (day) => {
    setBusinessHours(prev => ({
      ...prev,
      [day]: {
        ...prev[day],
        isOpen: !prev[day].isOpen
      }
    }));
  };

  const handleDepositChange = (value) => {
    const newValue = Math.min(Math.max(0, value), 100);
    setDepositPercentage(newValue);
  };

  const handleAutoConfirmChange = (value) => {
    setAutoConfirm(value);
  };

  const handleAddHoliday = () => {
    if (newHolidayDate && !holidayDates.includes(newHolidayDate)) {
      setHolidayDates(prev => [...prev, newHolidayDate].sort());
      setNewHolidayDate('');
    }
  };

  const handleRemoveHoliday = (dateToRemove) => {
    setHolidayDates(prev => prev.filter(date => date !== dateToRemove));
  };

  const checkForBookingConflicts = (blockedDate, startTime, duration) => {
    const blockStart = dayjs(`${blockedDate} ${startTime}`);
    const blockEnd = blockStart.add(parseInt(duration), 'minute');
    
    const conflicts = existingBookings.filter(booking => {
      // Only consider active bookings (not cancelled or rejected) as conflicts
      if (booking.status === 'cancelled' || booking.status === 'rejected') {
        return false;
      }

      const bookingStart = dayjs(booking.dateTime);
      const bookingEnd = bookingStart.add(
        (booking.totalDuration?.hours || 0) * 60 + (booking.totalDuration?.minutes || 0),
        'minute'
      );

      return (
        (blockStart.isBefore(bookingEnd) && blockEnd.isAfter(bookingStart)) ||
        blockStart.isSame(bookingStart)
      );
    });

    return conflicts;
  };

  const handleAddBlockedTime = () => {
    if (newBlockedTime.date && newBlockedTime.startTime) {
      const conflicts = checkForBookingConflicts(
        newBlockedTime.date,
        newBlockedTime.startTime,
        newBlockedTime.duration
      );

      if (conflicts.length > 0) {
        const conflictDetails = conflicts.map(booking => {
          const time = dayjs(booking.dateTime).format('HH:mm');
          return `${booking.userName || booking.userEmail} at ${time}`;
        }).join(', ');

        setConflictError(
          `Error: Cannot block this time slot due to active bookings for: ${conflictDetails}. ` +
          `To block this time, please cancel or reject the conflicting appointments first.`
        );
        return;
      }

      setConflictError('');
      const newBlock = {
        ...newBlockedTime,
        id: Date.now().toString()
      };
      setBlockedTimes(prev => [...prev, newBlock]);
      setNewBlockedTime({
        date: '',
        startTime: '',
        duration: '60'
      });
    }
  };

  const handleRemoveBlockedTime = (id) => {
    setBlockedTimes(prev => prev.filter(time => time.id !== id));
  };

  const saveSettings = async () => {
    try {
      setSaveStatus('Saving...');
      await setDoc(doc(db, 'settings', 'booking'), {
        businessHours,
        holidayDates,
        blockedTimes,
        depositPercentage,
        autoConfirm,
        lastUpdated: new Date().toISOString()
      });
      setSaveStatus('Settings saved successfully!');
      setTimeout(() => setSaveStatus(''), 3000);
    } catch (error) {
      console.error('Error saving settings:', error);
      setSaveStatus('Error saving settings');
    }
  };

  const initializeDefaultSettings = async () => {
    try {
      setSaveStatus('Initializing default settings...');
      await setDoc(doc(db, 'settings', 'booking'), {
        businessHours,
        holidayDates: [],
        blockedTimes: [],
        depositPercentage: 0,
        autoConfirm: false,
        lastUpdated: new Date().toISOString()
      });
      setSaveStatus('Default settings initialized successfully!');
      setTimeout(() => setSaveStatus(''), 3000);
    } catch (error) {
      console.error('Error initializing settings:', error);
      setSaveStatus('Error initializing settings');
    }
  };

  if (loading) {
    return <div>Loading settings...</div>;
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold text-gray-900">Booking Settings</h2>
        <button
          onClick={initializeDefaultSettings}
          className="px-4 py-2 bg-primary text-white rounded hover:bg-primary/90 transition-colors"
        >
          Initialize Default Settings
        </button>
      </div>

      <BookingStatusSection 
        autoConfirm={autoConfirm}
        onAutoConfirmChange={handleAutoConfirmChange}
      />

      <DepositSection 
        depositPercentage={depositPercentage}
        onDepositChange={handleDepositChange}
      />

      <BusinessHoursSection 
        businessHours={businessHours}
        onHoursChange={handleHoursChange}
        onDayToggle={handleDayToggle}
      />

      <HolidayDatesSection 
        holidayDates={holidayDates}
        newHolidayDate={newHolidayDate}
        onNewHolidayDateChange={setNewHolidayDate}
        onAddHoliday={handleAddHoliday}
        onRemoveHoliday={handleRemoveHoliday}
      />

      <BlockedTimesSection 
        blockedTimes={blockedTimes}
        newBlockedTime={newBlockedTime}
        onNewBlockedTimeChange={setNewBlockedTime}
        onAddBlockedTime={handleAddBlockedTime}
        onRemoveBlockedTime={handleRemoveBlockedTime}
        conflictError={conflictError}
      />

      <div className="flex justify-end">
        <button
          onClick={saveSettings}
          className="button-secondary"
        >
          Save Settings
        </button>
      </div>
      {saveStatus && (
        <div className="text-center text-sm font-medium text-gray-700">
          {saveStatus}
        </div>
      )}
    </div>
  );
};

export default BookingSettings;
