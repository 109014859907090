import React, { useState, useEffect } from 'react';
import { db } from '../../config/firebase';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

const DateTimeSelection = ({ selectedServices, onTimeSlotSelect }) => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locations, setLocations] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(dayjs());
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [existingBookings, setExistingBookings] = useState([]);
  const [businessHours, setBusinessHours] = useState({
    monday: { start: '09:00', end: '17:00', isOpen: true },
    tuesday: { start: '09:00', end: '17:00', isOpen: true },
    wednesday: { start: '09:00', end: '17:00', isOpen: true },
    thursday: { start: '09:00', end: '17:00', isOpen: true },
    friday: { start: '09:00', end: '17:00', isOpen: true },
    saturday: { start: '09:00', end: '17:00', isOpen: false },
    sunday: { start: '09:00', end: '17:00', isOpen: false },
  });
  const [holidayDates, setHolidayDates] = useState([]);
  const [blockedTimes, setBlockedTimes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Calculate total duration of selected services
  const totalDuration = selectedServices.reduce((total, service) => {
    const hours = service.bookingLength?.hours || 0;
    const minutes = service.bookingLength?.minutes || 0;
    return total + (hours * 60) + minutes;
  }, 0);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const locationsSnapshot = await getDocs(collection(db, 'locations'));
        const locationsList = locationsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setLocations(locationsList);
        
        // If there's only one location, auto-select it
        if (locationsList.length === 1) {
          setSelectedLocation(locationsList[0]);
        }
      } catch (error) {
        console.error('Error fetching locations:', error);
        setError('Error loading locations');
      }
    };

    const fetchBookingSettings = async () => {
      try {
        const bookingSettingsRef = doc(db, 'settings', 'booking');
        const bookingSettingsDoc = await getDoc(bookingSettingsRef);
        
        if (bookingSettingsDoc.exists()) {
          const data = bookingSettingsDoc.data();
          setBusinessHours(data.businessHours || businessHours);
          setHolidayDates(data.holidayDates || []);
          setBlockedTimes(data.blockedTimes || []);
        }
      } catch (error) {
        console.error('Error fetching booking settings:', error);
        setError('Using default business hours');
      } finally {
        setLoading(false);
      }
    };

    Promise.all([fetchLocations(), fetchBookingSettings()]);
  }, []);

  useEffect(() => {
    if (selectedDate) {
      fetchExistingBookings();
    }
  }, [selectedDate]);

  const fetchExistingBookings = async () => {
    if (!selectedDate || !selectedLocation) return;

    const startOfDay = dayjs(selectedDate).startOf('day').toDate();
    const endOfDay = dayjs(selectedDate).endOf('day').toDate();

    // Only query by date range, filter by location in memory
    const bookingsQuery = query(
      collection(db, 'bookings'),
      where('dateTime', '>=', startOfDay),
      where('dateTime', '<=', endOfDay)
    );

    try {
      const querySnapshot = await getDocs(bookingsQuery);
      const bookings = querySnapshot.docs
        .map(doc => ({
          ...doc.data(),
          id: doc.id
        }))
        // Filter by location in memory
        .filter(booking => booking.locationId === selectedLocation.id);
        
      setExistingBookings(bookings);
      generateAvailableTimeSlots(selectedDate, bookings);
    } catch (error) {
      console.error('Error fetching bookings:', error);
      setError('Error loading available times');
    }
  };

  const isTimeSlotBlocked = (timeSlot) => {
    const slotStart = dayjs(timeSlot);
    const slotEnd = slotStart.add(totalDuration, 'minute');
    const slotDate = slotStart.format('YYYY-MM-DD');

    return blockedTimes.some(block => {
      if (block.date !== slotDate) return false;

      const blockStart = dayjs(`${block.date} ${block.startTime}`);
      const blockEnd = blockStart.add(parseInt(block.duration), 'minute');

      return (
        slotStart.isBetween(blockStart, blockEnd, null, '[)') ||
        slotEnd.isBetween(blockStart, blockEnd, null, '(]') ||
        blockStart.isBetween(slotStart, slotEnd, null, '[)') ||
        blockEnd.isBetween(slotStart, slotEnd, null, '(]')
      );
    });
  };

  const isTimeSlotAvailable = (timeSlot, existingBookings) => {
    if (isTimeSlotBlocked(timeSlot)) {
      return false;
    }

    const slotStart = dayjs(timeSlot);
    const slotEnd = slotStart.add(totalDuration, 'minute');

    // Only consider confirmed and pending bookings when checking for conflicts
    const activeBookings = existingBookings.filter(booking => 
      ['confirmed', 'pending'].includes(booking.status)
    );

    return !activeBookings.some(booking => {
      const bookingStart = dayjs(booking.dateTime.toDate());
      const bookingDuration = booking.services.reduce((total, service) => {
        return total + ((service.bookingLength?.hours || 0) * 60) + (service.bookingLength?.minutes || 0);
      }, 0);
      const bookingEnd = bookingStart.add(bookingDuration, 'minute');

      // Check if the new slot overlaps with an existing booking
      return (
        slotStart.isBetween(bookingStart, bookingEnd, null, '[)') ||
        slotEnd.isBetween(bookingStart, bookingEnd, null, '(]') ||
        bookingStart.isBetween(slotStart, slotEnd, null, '[)') ||
        bookingEnd.isBetween(slotStart, slotEnd, null, '(]')
      );
    });
  };

  const generateAvailableTimeSlots = (date, bookings) => {
    if (!date) return [];

    const dayOfWeek = dayjs(date).format('dddd').toLowerCase();
    const dayHours = businessHours[dayOfWeek];

    if (!dayHours?.isOpen) {
      setAvailableTimeSlots([]);
      return;
    }

    const [startHour, startMinute] = dayHours.start.split(':').map(Number);
    const [endHour, endMinute] = dayHours.end.split(':').map(Number);

    const slots = [];
    let currentTime = dayjs(date).hour(startHour).minute(startMinute);
    const endTime = dayjs(date).hour(endHour).minute(endMinute);

    // If the selected date is today, only show future time slots
    const now = dayjs();
    if (dayjs(date).isSame(now, 'day')) {
      currentTime = dayjs.max(currentTime, now);
      // Round up to the next 30-minute interval
      const minutes = currentTime.minute();
      if (minutes > 0) {
        currentTime = currentTime.minute(Math.ceil(minutes / 30) * 30);
      }
    }

    while (currentTime.isBefore(endTime)) {
      // Check if there's enough time for the service before closing
      const slotEndTime = currentTime.add(totalDuration, 'minute');
      if (slotEndTime.isBefore(endTime) || slotEndTime.isSame(endTime)) {
        if (isTimeSlotAvailable(currentTime, bookings)) {
          slots.push(currentTime.toDate());
        }
      }
      currentTime = currentTime.add(30, 'minute');
    }

    setAvailableTimeSlots(slots);
  };

  const handleDateSelect = (date) => {
    const jsDate = date.toDate();
    setSelectedDate(jsDate);
    fetchExistingBookings();
  };

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
    setSelectedDate(null);
    setAvailableTimeSlots([]);
  };

  const handleTimeSlotSelect = (time) => {
    onTimeSlotSelect(time, selectedLocation);
  };

  const isDateDisabled = (date) => {
    const dayOfWeek = date.format('dddd').toLowerCase();
    const isHoliday = holidayDates.includes(date.format('YYYY-MM-DD'));
    const isDayOff = !businessHours[dayOfWeek]?.isOpen;
    const isPastDate = date.isBefore(dayjs(), 'day');
    
    // Check if the entire day is blocked
    const hasFullDayBlock = blockedTimes.some(block => {
      if (block.date !== date.format('YYYY-MM-DD')) return false;
      return parseInt(block.duration) >= 24 * 60; // 24 hours in minutes
    });
    
    return isHoliday || isDayOff || isPastDate || hasFullDayBlock;
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-gray-600">Loading available times...</div>
      </div>
    );
  }

  const renderLocationSelection = () => (
    <div className="bg-white rounded-lg shadow p-6 mb-8">
      <h3 className="text-lg font-semibold mb-4">Select Location</h3>
      {locations.length === 0 ? (
        <div className="text-gray-500">No locations available</div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {locations.map(location => (
            <button
              key={location.id}
              onClick={() => handleLocationSelect(location)}
              className={`p-4 border rounded-lg text-left transition-colors ${
                selectedLocation?.id === location.id
                  ? 'border-primary bg-primary/10'
                  : 'hover:border-primary hover:bg-primary/5'
              }`}
            >
              <div className="font-medium">{location.name}</div>
            </button>
          ))}
        </div>
      )}
    </div>
  );

  const renderCalendar = () => {
    const firstDayOfMonth = currentMonth.startOf('month');
    const startOfCalendar = firstDayOfMonth.startOf('week');
    const days = Array.from({ length: 42 }, (_, i) => startOfCalendar.add(i, 'day'));
    const today = dayjs();

    return (
      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex items-center justify-between mb-4">
          <button
            onClick={() => setCurrentMonth(prev => prev.subtract(1, 'month'))}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <div className="text-xl font-semibold">
            {currentMonth.format('MMMM YYYY')}
          </div>
          <button
            onClick={() => setCurrentMonth(prev => prev.add(1, 'month'))}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
        <div className="grid grid-cols-7 gap-2">
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
            <div key={day} className="text-center text-sm font-medium text-gray-500 p-2">
              {day}
            </div>
          ))}
          {days.map((date, index) => {
            const isToday = date.format('YYYY-MM-DD') === today.format('YYYY-MM-DD');
            const isCurrentMonth = date.month() === currentMonth.month();
            const isDisabled = isDateDisabled(date);
            const isSelected = selectedDate && date.format('YYYY-MM-DD') === dayjs(selectedDate).format('YYYY-MM-DD');

            return (
              <button
                key={index}
                onClick={() => !isDisabled && handleDateSelect(date)}
                disabled={isDisabled}
                className={`
                  p-2 text-sm rounded-lg transition-colors
                  ${isDisabled ? 'bg-gray-100 text-gray-400 cursor-not-allowed' : 
                    isSelected ? 'bg-primary text-white' :
                    isToday ? 'bg-primary/10 border-primary' :
                    'hover:bg-primary/10'
                  }
                  ${!isCurrentMonth ? 'text-gray-400' : ''}
                `}
              >
                {date.format('D')}
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-8">
      {error && (
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">{error}</p>
            </div>
          </div>
        </div>
      )}

      {renderLocationSelection()}

      {selectedLocation && (
        <>
          {renderCalendar()}

          {selectedDate && (
            <div className="bg-white rounded-lg shadow p-6">
              <h3 className="text-lg font-semibold mb-4">Select a Time</h3>
              {availableTimeSlots.length > 0 ? (
                <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 gap-2">
                  {availableTimeSlots.map(time => (
                    <button
                      key={time.toISOString()}
                      onClick={() => handleTimeSlotSelect(time)}
                      className="p-2 text-sm border rounded-lg hover:bg-primary/10 transition-colors"
                    >
                      {dayjs(time).format('h:mm A')}
                    </button>
                  ))}
                </div>
              ) : (
                <div className="text-center text-gray-500">
                  No available time slots for this date
                </div>
              )}
            </div>
          )}

          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-semibold mb-2">Appointment Duration</h3>
            <p className="text-gray-600">
              Total duration: {Math.floor(totalDuration / 60)}h {totalDuration % 60}m
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default DateTimeSelection;
