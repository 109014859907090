import React, { useState, useEffect } from 'react';
import { db } from '../config/firebase';
import { doc, getDoc } from 'firebase/firestore';
import SectionSideBySide from '../components/SectionTemplate/SectionSideBySide';
import contactHero from '../assets/images/contactHero.jpeg';
import { sendContactFormEmail } from '../utils/emailService';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [businessHours, setBusinessHours] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const dayOrder = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
  ];

  useEffect(() => {
    const fetchBusinessHours = async () => {
      try {
        const settingsDoc = await getDoc(doc(db, 'settings', 'booking'));
        if (settingsDoc.exists()) {
          const data = settingsDoc.data();
          setBusinessHours(data.businessHours);
        }
      } catch (error) {
        console.error('Error fetching business hours:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessHours();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setSubmitStatus(null);

    try {
      await sendContactFormEmail(formData);
      setSubmitStatus('success');
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      console.error('Error sending email:', error);
      setSubmitStatus('error');
    } finally {
      setSubmitting(false);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const formatDayName = (day) => {
    return day.charAt(0).toUpperCase() + day.slice(1);
  };

  const formatTime = (time) => {
    if (!time) return '';
    
    try {
      const [hours, minutes] = time.split(':');
      const hour = parseInt(hours);
      const ampm = hour >= 12 ? 'PM' : 'AM';
      const hour12 = hour % 12 || 12;
      return `${hour12}:${minutes} ${ampm}`;
    } catch (error) {
      return time;
    }
  };

  const renderBusinessHours = () => {
    if (loading) {
      return <p className="font-body">Loading hours...</p>;
    }

    if (!businessHours) {
      return (
        <div className="font-body leading-relaxed space-y-2">
          <p>Monday - Friday: 9:00 AM - 7:00 PM</p>
          <p>Saturday: 10:00 AM - 6:00 PM</p>
          <p>Sunday: Closed</p>
        </div>
      );
    }

    return (
      <div className="font-body leading-relaxed space-y-2">
        {dayOrder.map(day => {
          const dayHours = businessHours[day];
          return (
            <p key={day} className="capitalize">
              {formatDayName(day)}: {
                dayHours?.isOpen 
                  ? `${formatTime(dayHours.start)} - ${formatTime(dayHours.end)}`
                  : 'Closed'
              }
            </p>
          );
        })}
      </div>
    );
  };

  return (
    <div className="pt-32">
      <SectionSideBySide
        img={contactHero} 
        heading="Get in Touch"
        text="We'd love to hear from you. Whether you have questions about our services, need skincare advice, or want to book an appointment, our team is here to help."
      />

      <section className="w-full py-12 sm:py-20 bg-white">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="flex flex-col md:flex-row gap-8 sm:gap-16">
            {/* Contact Form */}
            <div className="w-full md:w-1/2">
              {submitStatus === 'success' ? (
                <div className="bg-green-50 border border-green-200 rounded-lg p-6 text-center">
                  <div className="mb-4">
                    <svg className="w-12 h-12 text-green-500 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <h3 className="text-xl font-secondary mb-2">Message Sent Successfully!</h3>
                  <p className="text-gray-600 font-body mb-6">Thank you for reaching out. We'll get back to you as soon as possible.</p>
                  <button
                    onClick={() => setSubmitStatus(null)}
                    className="bg-primary text-white font-body tracking-widest px-8 py-3 hover:bg-primary/90 transition-colors rounded-lg"
                  >
                    SEND ANOTHER MESSAGE
                  </button>
                </div>
              ) : (
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label htmlFor="name" className="block text-sm font-body tracking-widest mb-2">
                      NAME
                    </label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      required
                      value={formData.name}
                      onChange={handleChange}
                      className="w-full px-4 py-3 border-2 border-primary/20 focus:border-primary focus:outline-none font-body rounded-lg"
                    />
                  </div>
                  <div>
                    <label htmlFor="email" className="block text-sm font-body tracking-widest mb-2">
                      EMAIL
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      required
                      value={formData.email}
                      onChange={handleChange}
                      className="w-full px-4 py-3 border-2 border-primary/20 focus:border-primary focus:outline-none font-body rounded-lg"
                    />
                  </div>
                  <div>
                    <label htmlFor="message" className="block text-sm font-body tracking-widest mb-2">
                      MESSAGE
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows="6"
                      className="w-full px-4 py-3 border-2 border-primary/20 focus:border-primary focus:outline-none font-body resize-none rounded-lg"
                      required
                    ></textarea>
                  </div>
                  {submitStatus === 'error' && (
                    <div className="bg-red-50 border border-red-200 rounded-md p-4">
                      <p className="text-red-600 text-sm font-body text-center">
                        There was an error sending your message. Please try again.
                      </p>
                    </div>
                  )}
                  <button
                    type="submit"
                    disabled={submitting}
                    className={`w-full bg-primary text-white font-body tracking-widest py-4 sm:py-3 rounded-lg transition-colors duration-200 ${
                      submitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-primary/90 active:bg-primary/80'
                    }`}
                  >
                    {submitting ? 'SENDING...' : 'SEND MESSAGE'}
                  </button>
                </form>
              )}
            </div>

            {/* Contact Information */}
            <div className="w-full md:w-1/2 space-y-8">
              <div>
                <h3 className="text-xl sm:text-2xl font-secondary mb-4">Contact</h3>
                <p className="font-body leading-relaxed">
                  Email: info@skinchalet.com
                </p>
              </div>
              <div>
                <h3 className="text-xl sm:text-2xl font-secondary mb-4">Hours</h3>
                <div className="bg-gray-50 rounded-lg p-4 sm:p-6">
                  {renderBusinessHours()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
