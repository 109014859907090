import React from 'react';
import dayjs from 'dayjs';

const CalendarView = ({ currentMonth, onPreviousMonth, onNextMonth, onDateClick, bookings, blockedTimes = [] }) => {
  const firstDayOfMonth = currentMonth.startOf('month');
  const startOfCalendar = firstDayOfMonth.startOf('week');
  const days = Array.from({ length: 42 }, (_, i) => startOfCalendar.add(i, 'day'));
  const today = dayjs();

  const getBookingsForDate = (date) => {
    return bookings.filter(booking => 
      dayjs(booking.dateTime).format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD')
    );
  };

  const getBlockedTimesForDate = (date) => {
    return blockedTimes.filter(block => 
      block.date === dayjs(date).format('YYYY-MM-DD')
    );
  };

  const getStatusCounts = (bookings) => {
    return bookings.reduce((acc, booking) => {
      const status = booking.status;
      const isImported = !booking.userId;
      const key = isImported ? `imported_${status}` : status;
      acc[key] = (acc[key] || 0) + 1;
      return acc;
    }, {});
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'confirmed':
        return 'bg-green-100 text-green-800 border-green-200';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800 border-yellow-200';
      case 'rejected':
        return 'bg-red-100 text-red-800 border-red-200';
      case 'cancelled':
        return 'bg-gray-100 text-gray-800 border-gray-200';
      case 'blocked':
        return 'bg-gray-200 text-gray-800 border-gray-300';
      default:
        if (status.startsWith('imported_')) {
          const baseStatus = status.replace('imported_', '');
          switch (baseStatus) {
            case 'confirmed':
              return 'bg-green-50 text-green-800 border-green-200';
            case 'pending':
              return 'bg-yellow-50 text-yellow-800 border-yellow-200';
            case 'rejected':
              return 'bg-red-50 text-red-800 border-red-200';
            case 'cancelled':
              return 'bg-gray-50 text-gray-800 border-gray-200';
            default:
              return 'bg-gray-50 text-gray-800 border-gray-200';
          }
        }
        return 'bg-gray-100 text-gray-800 border-gray-200';
    }
  };

  const formatBlockedTime = (block) => {
    const startTime = dayjs(`${block.date} ${block.startTime}`);
    const endTime = startTime.add(parseInt(block.duration), 'minute');
    return `${startTime.format('h:mm A')} - ${endTime.format('h:mm A')}`;
  };

  const formatStatusLabel = (status, count) => {
    if (status.startsWith('imported_')) {
      const baseStatus = status.replace('imported_', '');
      return `${count} Imp. ${baseStatus.charAt(0).toUpperCase() + baseStatus.slice(1)}`;
    }
    return `${count} ${status.charAt(0).toUpperCase() + status.slice(1)}`;
  };

  const formatMobileStatusLabel = (status, count) => {
    if (status.startsWith('imported_')) {
      return `${count} Imp`;
    }
    return count.toString();
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg p-2 sm:p-4">
      <div className="flex items-center justify-between mb-4">
        <button
          onClick={onPreviousMonth}
          className="p-3 hover:bg-gray-100 rounded-full touch-manipulation"
          aria-label="Previous month"
        >
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <div className="text-lg sm:text-xl font-semibold text-center">
          {currentMonth.format('MMMM YYYY')}
        </div>
        <button
          onClick={onNextMonth}
          className="p-3 hover:bg-gray-100 rounded-full touch-manipulation"
          aria-label="Next month"
        >
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>

      {/* Legend */}
      <div className="grid grid-cols-3 sm:flex sm:flex-wrap gap-2 sm:gap-4 mb-4 text-xs sm:text-sm px-2">
        <div className="flex items-center">
          <div className="w-3 h-3 rounded-full bg-green-100 border border-green-200 mr-1"></div>
          <span>Confirmed</span>
        </div>
        <div className="flex items-center">
          <div className="w-3 h-3 rounded-full bg-yellow-100 border border-yellow-200 mr-1"></div>
          <span>Pending</span>
        </div>
        <div className="flex items-center">
          <div className="w-3 h-3 rounded-full bg-red-100 border border-red-200 mr-1"></div>
          <span>Rejected</span>
        </div>
        <div className="flex items-center">
          <div className="w-3 h-3 rounded-full bg-gray-100 border border-gray-200 mr-1"></div>
          <span>Cancelled</span>
        </div>
        <div className="flex items-center">
          <div className="w-3 h-3 rounded-full bg-gray-200 border border-gray-300 mr-1"></div>
          <span>Blocked</span>
        </div>
        <div className="flex items-center">
          <div className="w-3 h-3 rounded-full bg-yellow-50 border border-yellow-200 mr-1"></div>
          <span>Imported</span>
        </div>
      </div>

      <div className="grid grid-cols-7 gap-1 sm:gap-2">
        {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day) => (
          <div key={day} className="text-center font-medium text-gray-500 p-1 sm:p-2 text-xs sm:text-sm">
            {day}
          </div>
        ))}
        {days.map((date, index) => {
          const dayBookings = getBookingsForDate(date);
          const dayBlockedTimes = getBlockedTimesForDate(date);
          const statusCounts = getStatusCounts(dayBookings);
          const isToday = date.format('YYYY-MM-DD') === today.format('YYYY-MM-DD');
          const isCurrentMonth = date.month() === currentMonth.month();
          const isPastDate = date.isBefore(today, 'day');
          const hasBlockedTime = dayBlockedTimes.length > 0;

          return (
            <div
              key={index}
              onClick={() => !isPastDate && onDateClick(date.toDate())}
              className={`border p-1 sm:p-2 min-h-[80px] sm:min-h-[100px] ${
                isPastDate ? 'bg-gray-100 cursor-not-allowed' : 'cursor-pointer hover:bg-gray-50'
              } ${isToday ? 'bg-primary/10 border-primary' : ''} 
              ${!isCurrentMonth ? 'text-gray-400' : ''}`}
            >
              <div className="text-right mb-1 text-xs sm:text-sm">{date.format('D')}</div>
              {!isPastDate && (
                <div className="space-y-1">
                  <div className="flex flex-wrap gap-1">
                    {Object.entries(statusCounts).map(([status, count]) => (
                      count > 0 && (
                        <div
                          key={status}
                          className={`inline-flex items-center justify-center px-1 sm:px-2 py-0.5 sm:py-1 text-[10px] sm:text-xs font-bold leading-none rounded-full border ${getStatusColor(status)}`}
                          title={formatStatusLabel(status, count)}
                        >
                          <span className="hidden sm:inline">{formatStatusLabel(status, count)}</span>
                          <span className="sm:hidden">{formatMobileStatusLabel(status, count)}</span>
                        </div>
                      )
                    ))}
                  </div>
                  {dayBlockedTimes.map((block, idx) => (
                    <div
                      key={idx}
                      className={`px-1 sm:px-2 py-0.5 rounded-full border ${getStatusColor('blocked')} text-[10px] sm:text-xs font-bold leading-none truncate`}
                      title={formatBlockedTime(block)}
                    >
                      <span className="hidden sm:inline">Blocked: {formatBlockedTime(block)}</span>
                      <span className="sm:hidden">Blocked</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CalendarView;
