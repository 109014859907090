import React from 'react';
import VariationForm from './VariationForm';

const ServiceForm = ({
  serviceForm,
  categories,
  onInputChange,
  onSubmit,
  isLoading,
  editingService,
  onCancel,
  variationForm,
  showVariationForm,
  onVariationInputChange,
  onAddVariation,
  onCancelVariation,
  onRemoveVariation,
  setShowVariationForm
}) => {
  return (
    <form onSubmit={onSubmit} className="space-y-6">
      <div>
        <label htmlFor="title" className="block text-sm font-medium text-gray-700">
          Title
        </label>
        <input
          type="text"
          name="title"
          id="title"
          required
          value={serviceForm.title}
          onChange={onInputChange}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        />
      </div>

      <div>
        <label htmlFor="category" className="block text-sm font-medium text-gray-700">
          Category
        </label>
        <select
          name="category"
          id="category"
          required
          value={serviceForm.category}
          onChange={onInputChange}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        >
          <option value="">Select a category</option>
          {categories.map((category) => (
            <option key={category.id} value={category.name}>
              {category.name}
            </option>
          ))}
        </select>
      </div>

      <div className="flex items-center">
        <input
          type="checkbox"
          name="hasVariations"
          id="hasVariations"
          checked={serviceForm.hasVariations}
          onChange={onInputChange}
          className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
        />
        <label htmlFor="hasVariations" className="ml-2 block text-sm text-gray-700">
          This service has variations
        </label>
      </div>

      {!serviceForm.hasVariations && (
        <div>
          <label htmlFor="price" className="block text-sm font-medium text-gray-700">
            Price (£)
          </label>
          <input
            type="text"
            name="price"
            id="price"
            required={!serviceForm.hasVariations}
            value={serviceForm.price}
            onChange={onInputChange}
            placeholder="99"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
          />
        </div>
      )}

      {serviceForm.hasVariations && (
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <h3 className="text-sm font-medium text-gray-700">Variations</h3>
            <button
              type="button"
              onClick={() => setShowVariationForm(true)}
              className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-primary hover:bg-primary/90"
            >
              Add Variation
            </button>
          </div>

          {showVariationForm && (
            <VariationForm
              variationForm={variationForm}
              onInputChange={onVariationInputChange}
              onAdd={onAddVariation}
              onCancel={onCancelVariation}
            />
          )}

          <div className="space-y-2">
            {serviceForm.variations.map((variation, index) => (
              <div key={index} className="flex justify-between items-center bg-gray-50 p-3 rounded-lg">
                <div>
                  <span className="font-medium">{variation.name}</span>
                  <span className="ml-2 text-gray-500">£{variation.price}</span>
                  <span className="ml-2 text-gray-500">
                    ({variation.bookingLength.hours}h {variation.bookingLength.minutes}m)
                  </span>
                  {variation.description && (
                    <p className="text-sm text-gray-500">{variation.description}</p>
                  )}
                </div>
                <button
                  type="button"
                  onClick={() => onRemoveVariation(index)}
                  className="text-red-600 hover:text-red-800"
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Booking Length
        </label>
        <div className="mt-1 grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="bookingLengthHours" className="block text-sm text-gray-500">
              Hours
            </label>
            <input
              type="number"
              name="bookingLengthHours"
              id="bookingLengthHours"
              min="0"
              required
              value={serviceForm.bookingLengthHours}
              onChange={onInputChange}
              className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="bookingLengthMinutes" className="block text-sm text-gray-500">
              Minutes
            </label>
            <input
              type="number"
              name="bookingLengthMinutes"
              id="bookingLengthMinutes"
              min="0"
              max="59"
              required
              value={serviceForm.bookingLengthMinutes}
              onChange={onInputChange}
              className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </div>
        </div>
      </div>

      <div>
        <label htmlFor="description" className="block text-sm font-medium text-gray-700">
          Description
        </label>
        <textarea
          name="description"
          id="description"
          required
          rows={4}
          value={serviceForm.description}
          onChange={onInputChange}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        />
      </div>

      <div className="space-y-4">
        <div className="flex items-center">
          <input
            type="checkbox"
            name="requireFullPayment"
            id="requireFullPayment"
            checked={serviceForm.requireFullPayment}
            onChange={onInputChange}
            className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
          />
          <label htmlFor="requireFullPayment" className="ml-2 block text-sm text-gray-700">
            Require Full Payment in Advance
          </label>
        </div>

        {!serviceForm.requireFullPayment && (
          <div>
            <label htmlFor="depositPercentage" className="block text-sm font-medium text-gray-700">
              Custom Deposit Percentage (optional)
            </label>
            <input
              type="number"
              name="depositPercentage"
              id="depositPercentage"
              min="0"
              max="100"
              value={serviceForm.depositPercentage}
              onChange={onInputChange}
              placeholder="Leave empty to use default deposit rate"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
            <p className="mt-1 text-sm text-gray-500">
              If left empty, the default deposit rate from booking settings will be used
            </p>
          </div>
        )}
      </div>

      <div className="flex items-center">
        <input
          type="checkbox"
          name="hidden"
          id="hidden"
          checked={serviceForm.hidden}
          onChange={onInputChange}
          className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
        />
        <label htmlFor="hidden" className="ml-2 block text-sm text-gray-700">
          Hidden Service (Only available for manual admin booking)
        </label>
      </div>

      <div className="flex space-x-4">
        <button
          type="submit"
          disabled={isLoading}
          className={`button-secondary flex-1 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {isLoading ? (editingService ? 'Updating...' : 'Adding...') : (editingService ? 'Update Service' : 'Add Service')}
        </button>
        
        {editingService && (
          <button
            type="button"
            onClick={onCancel}
            className="button-secondary bg-gray-500 hover:bg-gray-600 px-4"
          >
            Cancel
          </button>
        )}
      </div>
    </form>
  );
};

export default ServiceForm;
